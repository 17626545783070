import React from "react";
import CustomScrollbar from "../../components/global/CustomScrollBar";
import {
  Box,
  Heading,
  Text,
  Link,
  UnorderedList,
  ListItem,
  List,
} from "@chakra-ui/react";
import { AppURL } from "../../utils/config";

const PrivacyPolicy = () => {
  return (
    <Box height="100vh" overflowY="auto">
      <CustomScrollbar>
        <Box mx="auto" bg="white" shadow="lg" borderRadius="md" p={4}>
          <Heading as="h2" size="lg" mb={4}>
            Privacy Policy
          </Heading>
          <Text mb={4}>
            Your privacy is important to us. It is iMongu Technologies Inc's
            policy to respect your privacy and comply with any applicable law
            and regulation regarding any personal information we may collect
            about you, including across our website,{" "}
            <Link href={AppURL} color="teal.500" isExternal>
              {AppURL}
            </Link>
            , and other sites we own and operate.
          </Text>
          <Text mb={4}>
            Personal information is any information about you which can be used
            to identify you. This includes information about you as a person
            (such as name, address, and date of birth), your devices, payment
            details, and even information about how you use a website or online
            service.
          </Text>
          <Text mb={4}>
            In the event our site contains links to third-party sites and
            services, please be aware that those sites and services have their
            own privacy policies. After following a link to any third-party
            content, you should read their posted privacy policy information
            about how they collect and use personal information. This Privacy
            Policy does not apply to any of your activities after you leave our
            site.
          </Text>
          <Text mb={4}>This policy is effective as of May 26, 2024.</Text>
          <Text mb={4}>Last updated: May 26, 2024.</Text>

          <Heading as="h3" size="md" mt={8} mb={4}>
            Information We Collect
          </Heading>
          <Text mb={4}>
            Information we collect falls into one of two categories:
            “voluntarily provided” information and “automatically collected”
            information.
          </Text>
          <Text mb={4}>
            “Voluntarily provided” information refers to any information you
            knowingly and actively provide us when using or participating in any
            of our services and promotions.
          </Text>
          <Text mb={4}>
            “Automatically collected” information refers to any information
            automatically sent by your devices in the course of accessing our
            products and services.
          </Text>

          <Heading as="h3" size="md" mt={8} mb={4}>
            Log Data
          </Heading>
          <Text mb={4}>
            When you visit our website, our servers may automatically log the
            standard data provided by your web browser. It may include your
            device’s Internet Protocol (IP) address, your browser type and
            version, the pages you visit, the time and date of your visit, the
            time spent on each page, and other details about your visit.
          </Text>
          <Text mb={4}>
            Additionally, if you encounter certain errors while using the site,
            we may automatically collect data about the error and the
            circumstances surrounding its occurrence. This data may include
            technical details about your device, what you were trying to do when
            the error happened, and other technical information relating to the
            problem. You may or may not receive notice of such errors.
          </Text>

          <Text mb={4}>
            Please be aware that while this information may not be personally
            identifying by itself, it may be possible to combine it with other
            data to personally identify individual persons.
          </Text>

          <Heading as="h3" size="md" mt={8} mb={4}>
            Device Data
          </Heading>
          <Text mb={4}>
            When you visit our website or interact with our services, we may
            automatically collect data about your device, such as:
          </Text>
          <UnorderedList mb={4} pl={4}>
            <ListItem>Device type</ListItem>
            <ListItem>Operating system</ListItem>
            <ListItem>Unique device identifiers</ListItem>
            <ListItem>Geo-location data</ListItem>
          </UnorderedList>

          <Text mb={4}>
            Data we collect can depend on the individual settings of your device
            and software. We recommend checking the policies of your device
            manufacturer or software provider to learn what information they
            make available to us.
          </Text>

          <Heading as="h3" size="md" mt={8} mb={4}>
            Personal Information
          </Heading>
          <Text mb={4}>
            We may ask for personal information — for example, when you
            subscribe to our newsletter or when you contact us — which may
            include one or more of the following:
          </Text>
          <UnorderedList mb={4} pl={4}>
            <ListItem>Name</ListItem>
            <ListItem>Email</ListItem>
            <ListItem>Company name</ListItem>
          </UnorderedList>
          <Heading as="h3" size="md" mt={8} mb={4}>
            User-Generated Content
          </Heading>
          <Text mb={4}>
            We consider “user-generated content” to be materials (text, image
            and/or video content) voluntarily supplied to us by our users for
            the purpose of publication, processing, or usage on our platform.
            All user-generated content is associated with the account or email
            address used to submit the materials.
          </Text>
          <Text mb={6}>
            Please be aware that any content you submit for the purpose of
            publication will be public after posting (and subsequent review or
            vetting process). Once published, it may be accessible to third
            parties not covered under this privacy policy.
          </Text>

          <Heading as="h3" size="md" mb={4}>
            Transaction Data
          </Heading>
          <Text mb={6}>
            Transaction data refers to data that accumulates over the normal
            course of operation on our platform. This may include transaction
            records, stored files, user profiles, analytics data and other
            metrics, as well as other types of information, created or
            generated, as users interact with our services.
          </Text>

          <Heading as="h3" size="md" mb={4}>
            Legitimate Reasons for Processing Your Personal Information
          </Heading>
          <Text mb={6}>
            We only collect and use your personal information when we have a
            legitimate reason for doing so. In which instance, we only collect
            personal information that is reasonably necessary to provide our
            services to you.
          </Text>

          <Heading as="h3" size="md" mb={4}>
            Collection and Use of Information
          </Heading>
          <Text mb={4}>
            We may collect personal information from you when you do any of the
            following on our website:
          </Text>
          <UnorderedList spacing={2} mb={6}>
            <ListItem>Register for an account</ListItem>
            <ListItem>Purchase a subscription</ListItem>
            <ListItem>
              Sign up to receive updates from us via email or social media
              channels
            </ListItem>
            <ListItem>
              Use a mobile device or web browser to access our content
            </ListItem>
            <ListItem>
              Contact us via email, social media, or on any similar technologies
            </ListItem>
            <ListItem>When you mention us on social media</ListItem>
          </UnorderedList>

          <Heading as="h3" size="md" mb={4}>
            Security of Your Personal Information
          </Heading>
          <Text>
            When we collect and process personal information, and while we
            retain this information, we will protect it within commercially
            acceptable means to prevent loss and theft, as well as unauthorized
            access, disclosure, copying, use, or modification.
          </Text>
          <Text mt={4}>
            Although we will do our best to protect the personal information you
            provide to us, we advise that no method of electronic transmission
            or storage is 100% secure and no one can guarantee absolute data
            security.
          </Text>
          <Text mt={4} mb={4}>
            You are responsible for selecting any password and its overall
            security strength, ensuring the security of your own information
            within the bounds of our services. For example, ensuring any
            passwords associated with accessing your personal information and
            accounts are secure and confidential.
          </Text>

          <Heading as="h4" size="md" mb={4}>
            How Long We Keep Your Personal Information
          </Heading>
          <Text mb={4}>
            We keep your personal information only for as long as we need to.
            This time period may depend on what we are using your information
            for, in accordance with this privacy policy. For example, if you
            have provided us with personal information as part of creating an
            account with us, we may retain this information for the duration
            your account exists on our system. If your personal information is
            no longer required for this purpose, we will delete it or make it
            anonymous by removing all details that identify you.
          </Text>
          <Text mb={6}>
            However, if necessary, we may retain your personal information for
            our compliance with a legal, accounting, or reporting obligation or
            for archiving purposes in the public interest, scientific, or
            historical research purposes or statistical purposes.
          </Text>

          <Heading as="h3" size="md" mb={4}>
            Children’s Privacy
          </Heading>
          <Text mb={6}>
            We do not aim any of our products or services directly at children
            under the age of 13 and we do not knowingly collect personal
            information about children under 13.
          </Text>

          <Heading as="h3" size="md" mb={4}>
            Disclosure of Personal Information to Third Parties
          </Heading>
          <Text mb={4}>We may disclose personal information to:</Text>
          <UnorderedList spacing={2} mb={6}>
            <ListItem>
              a parent, subsidiary or affiliate of our company
            </ListItem>
            <ListItem>
              third-party service providers for the purpose of enabling them to
              provide their services, including (without limitation) IT service
              providers, data storage, hosting and server providers, ad
              networks, analytics, error loggers, debt collectors, maintenance
              or problem-solving providers, professional advisors, and payment
              systems operators
            </ListItem>
            <ListItem>
              our employees, contractors, and/or related entities
            </ListItem>
            <ListItem>
              our existing or potential agents or business partners
            </ListItem>
            <ListItem>
              credit reporting agencies, courts, tribunals, and regulatory
              authorities, in the event you fail to pay for goods or services we
              have provided to you
            </ListItem>
            <ListItem>
              courts, tribunals, regulatory authorities, and law enforcement
              officers, as required by law, in connection with any actual or
              prospective legal proceedings, or in order to establish, exercise,
              or defend our legal rights
            </ListItem>
            <ListItem>
              third parties, including agents or sub-contractors who assist us
              in providing information, products, services, or direct marketing
              to you
            </ListItem>
            <ListItem>third parties to collect and process data</ListItem>
            <ListItem>
              an entity that buys, or to which we transfer all or substantially
              all of our assets and business
            </ListItem>
          </UnorderedList>

          <Text mb={6}>Third parties we currently use include:</Text>
          <UnorderedList spacing={2} mb={6}>
            <ListItem>Google Analytics</ListItem>
            <ListItem>Stripe</ListItem>
          </UnorderedList>

          <Heading as="h3" size="md" mb={4}>
            International Transfers of Personal Information
          </Heading>
          <Text mb={4}>
            The personal information we collect is stored and/or processed in
            United States, or where we or our partners, affiliates, and
            third-party providers maintain facilities.
          </Text>
          <Text mb={6}>
            The countries to which we store, process, or transfer your personal
            information may not have the same data protection laws as the
            country in which you initially provided the information. If we
            transfer your personal information to third parties in other
            countries: (i) we will perform those transfers in accordance with
            the requirements of applicable law; and (ii) we will protect the
            transferred personal information in accordance with this privacy
            policy.
          </Text>

          <Heading as="h3" size="md" mb={4}>
            Your Rights and Controlling Your Personal Information
          </Heading>
          <Text mb={4}>
            <strong>Your choice:</strong> By providing personal information to
            us, you understand we will collect, hold, use, and disclose your
            personal information in accordance with this privacy policy. You do
            not have to provide personal information to us, however, if you do
            not, it may affect your use of our website or the products and/or
            services offered on or through it.
          </Text>
          <Text mb={4}>
            <strong>Information from third parties:</strong> If we receive
            personal information about you from a third party, we will protect
            it as set out in this privacy policy. If you are a third party
            providing personal information about somebody else, you represent
            and warrant that you have such person’s consent to provide the
            personal information to us.
          </Text>
          <Text mb={4}>
            <strong>Marketing permission:</strong> If you have previously agreed
            to us using your personal information for direct marketing purposes,
            you may change your mind at any time by contacting us using the
            details below.
          </Text>
          <Text mb={4}>
            <strong>Access:</strong> You may request details of the personal
            information that we hold about you.
          </Text>
          <Text mb={4}>
            <strong>Correction:</strong> If you believe that any information we
            hold about you is inaccurate, out of date, incomplete, irrelevant,
            or misleading, please contact us using the details provided in this
            privacy policy. We will take reasonable steps to correct any
            information found to be inaccurate, incomplete, misleading, or out
            of date.
          </Text>
          <Text mb={4}>
            <strong>Non-discrimination:</strong> We will not discriminate
            against you for exercising any of your rights over your personal
            information. Unless your personal information is required to provide
            you with a particular service or offer (for example processing
            transaction data), we will not deny you goods or services and/or
            charge you different prices or rates for goods or services,
            including through granting discounts or other benefits, or imposing
            penalties, or provide you with a different level or quality of goods
            or services.
          </Text>
          <Text mb={4}>
            <strong>Downloading of Personal Information:</strong> We provide a
            means for you to download the personal information you have shared
            through our site. Please contact us for more information.
          </Text>
          <Text mb={4}>
            <strong>Notification of data breaches:</strong> We will comply with
            laws applicable to us in respect of any data breach.
          </Text>
          <Text mb={4}>
            <strong>Complaints:</strong> If you believe that we have breached a
            relevant data protection law and wish to make a complaint, please
            contact us using the details below and provide us with full details
            of the alleged breach. We will promptly investigate your complaint
            and respond to you, in writing, setting out the outcome of our
            investigation and the steps we will take to deal with your
            complaint. You also have the right to contact a regulatory body or
            data protection authority in relation to your complaint.
          </Text>
          <Text>
            <strong>Unsubscribe:</strong> To unsubscribe from our email database
            or opt-out of communications (including marketing communications),
            please contact us using the details provided in this privacy policy,
            or opt-out using the opt-out facilities provided in the
            communication. We may need to request specific information from you
            to help us confirm your identity.
          </Text>
          <Heading as="h3" size="md" mb={4} mt={4}>
            Additional Disclosures for General Data Protection Regulation (GDPR)
            Compliance (EU)
          </Heading>

          <Heading as="h4" size="md" mb={2}>
            Data Controller / Data Processor
          </Heading>
          <Text mb={4}>
            The GDPR distinguishes between organisations that process personal
            information for their own purposes (known as &quot;data
            controllers&quot;) and organisations that process personal
            information on behalf of other organisations (known as &quot;data
            processors&quot;). We, iMongu Technologies Inc, located at the
            address provided in our Contact Us section, are a Data Controller
            with respect to the personal information you provide to us.
          </Text>

          <Heading as="h4" size="md" mb={2}>
            Legal Bases for Processing Your Personal Information
          </Heading>
          <Text mb={4}>
            We will only collect and use your personal information when we have
            a legal right to do so. In which case, we will collect and use your
            personal information lawfully, fairly, and in a transparent manner.
            If we seek your consent to process your personal information, and
            you are under 16 years of age, we will seek your parent or legal
            guardian’s consent to process your personal information for that
            specific purpose.
          </Text>

          <Text mb={4}>
            Our lawful bases depend on the services you use and how you use
            them. This means we only collect and use your information on the
            following grounds:
          </Text>

          <Heading as="h4" size="md" mb={2}>
            Consent From You
          </Heading>
          <Text mb={4}>
            Where you give us consent to collect and use your personal
            information for a specific purpose. You may withdraw your consent at
            any time using the facilities we provide; however, this will not
            affect any use of your information that has already taken place. You
            may consent to providing your email address for the purpose of
            receiving marketing emails from us. While you may unsubscribe at any
            time, we cannot recall any email we have already sent. If you have
            any further enquiries about how to withdraw your consent, please
            feel free to enquire using the details provided in the Contact Us
            section of this privacy policy.
          </Text>

          <Heading as="h4" size="md" mb={2}>
            Performance of a Contract or Transaction
          </Heading>
          <Text mb={4}>
            Where you have entered into a contract or transaction with us, or in
            order to take preparatory steps prior to our entering into a
            contract or transaction with you. For example, if you purchase a
            product, service, or subscription from us, we may need to use your
            personal and payment information in order to process and deliver
            your order.
          </Text>

          <Heading as="h4" size="md" mb={2}>
            Our Legitimate Interests
          </Heading>
          <Text mb={4}>
            Where we assess it is necessary for our legitimate interests, such
            as for us to provide, operate, improve and communicate our services.
            We consider our legitimate interests to include research and
            development, understanding our audience, marketing and promoting our
            services, measures taken to operate our services efficiently,
            marketing analysis, and measures taken to protect our legal rights
            and interests.
          </Text>

          <Heading as="h4" size="md" mb={2}>
            Compliance with Law
          </Heading>
          <Text mb={4}>
            In some cases, we may have a legal obligation to use or keep your
            personal information. Such cases may include (but are not limited
            to) court orders, criminal investigations, government requests, and
            regulatory obligations. If you have any further enquiries about how
            we retain personal information in order to comply with the law,
            please feel free to enquire using the details provided in the
            Contact Us section of this privacy policy.
          </Text>

          <Heading as="h4" size="md" mb={2}>
            International Transfers Outside of the European Economic Area (EEA)
          </Heading>
          <Text mb={4}>
            We will ensure that any transfer of personal information from
            countries in the European Economic Area (EEA) to countries outside
            the EEA will be protected by appropriate safeguards, for example by
            using standard data protection clauses approved by the European
            Commission, or the use of binding corporate rules or other legally
            accepted means.
          </Text>

          <Heading as="h4" size="md" mb={2}>
            Your Rights and Controlling Your Personal Information
          </Heading>

          <UnorderedList mb={4}>
            <ListItem>
              <strong>Restrict:</strong> You have the right to request that we
              restrict the processing of your personal information if (i) you
              are concerned about the accuracy of your personal information;
              (ii) you believe your personal information has been unlawfully
              processed; (iii) you need us to maintain the personal information
              solely for the purpose of a legal claim; or (iv) we are in the
              process of considering your objection in relation to processing on
              the basis of legitimate interests.
            </ListItem>
            <ListItem>
              <strong>Objecting to processing:</strong> You have the right to
              object to processing of your personal information that is based on
              our legitimate interests or public interest. If this is done, we
              must provide compelling legitimate grounds for the processing
              which overrides your interests, rights, and freedoms, in order to
              proceed with the processing of your personal information.
            </ListItem>
            <ListItem>
              <strong>Data portability:</strong> You may have the right to
              request a copy of the personal information we hold about you.
              Where possible, we will provide this information in CSV format or
              other easily readable machine format. You may also have the right
              to request that we transfer this personal information to a third
              party.
            </ListItem>
            <ListItem>
              <strong>Deletion:</strong> You may have a right to request that we
              delete the personal information we hold about you at any time, and
              we will take reasonable steps to delete your personal information
              from our current records. If you ask us to delete your personal
              information, we will let you know how the deletion affects your
              use of our website or products and services.
            </ListItem>
          </UnorderedList>
          <Heading as="h3" size="md" mb={4} mt={4}>
            Additional Disclosures for U.S. States Privacy Law Compliance
          </Heading>

          <Text mb={4}>
            The following section includes provisions that comply with the
            privacy laws of these states (California, Colorado, Delaware,
            Florida, Virginia, and Utah) and is applicable only to the residents
            of those states. Specific references to a particular state (in a
            heading or in the text) are only a reference to that state's law and
            applies only to that state's residents. Non-state specific language
            applies to all of the states listed above.
          </Text>

          {/* Do Not Track */}
          <Heading as="h4" size="md" mb={2}>
            Do Not Track
          </Heading>
          <Text mb={4}>
            Some browsers have a "Do Not Track" feature that lets you tell
            websites that you do not want to have your online activities
            tracked. At this time, we do not respond to browser "Do Not Track"
            signals.
          </Text>
          <Text mb={4}>
            We adhere to the standards outlined in this privacy policy, ensuring
            we collect and process personal information lawfully, fairly,
            transparently, and with legitimate, legal reasons for doing so.
          </Text>

          {/* Cookies and Pixels */}
          <Heading as="h4" size="md" mb={2}>
            Cookies and Pixels
          </Heading>
          <Text mb={4}>
            At all times, you may decline cookies from our site if your browser
            permits. Most browsers allow you to activate settings on your
            browser to refuse the setting of all or some cookies. Accordingly,
            your ability to limit cookies is based only on your browser’s
            capabilities. Please refer to the Cookies section of this privacy
            policy for more information.
          </Text>
          <Heading as="h4" size="md" mb={2}>
            California Privacy Laws - CPPA
          </Heading>
          <Text mb={4}>
            Under California Civil Code Section 1798.83, if you live in
            California and your business relationship with us is mainly for
            personal, family, or household purposes, you may ask us about the
            information we release to other organizations for their marketing
            purposes. In accordance with your right to non-discrimination, we
            may offer you certain financial incentives permitted by the
            California Consumer Privacy Act, and the California Privacy Rights
            Act (collectively, CCPA) that can result in different prices, rates,
            or quality levels for the goods or services we provide. Any
            CCPA-permitted financial incentive we offer will reasonably relate
            to the value of your personal information, and we will provide
            written terms that describe clearly the nature of such an offer.
            Participation in a financial incentive program requires your prior
            opt-in consent, which you may revoke at any time.
          </Text>
          <Text mb={4}>
            Under California Civil Code Section 1798.83, if you live in
            California and your business relationship with us is mainly for
            personal, family, or household purposes, you may ask us about the
            information we release to other organizations for their marketing
            purposes. To make such a request, please contact us using the
            details provided in this privacy policy with “Request for California
            privacy information” in the subject line. You may make this type of
            request once every calendar year. We will email you a list of
            categories of personal information we revealed to other
            organisations for their marketing purposes in the last calendar
            year, along with their names and addresses. Not all personal
            information shared in this way is covered by Section 1798.83 of the
            California Civil Code.
          </Text>
          <Heading as="h4" size="md" mb={2}>
            California Notice of Collection
          </Heading>
          <Text mb={4}>
            In the past 12 months, we have collected the following categories of
            personal information enumerated in the CCPA:
          </Text>
          <List mb={4} spacing={2}>
            <ListItem>
              Identifiers, such as name, email address, phone number, account
              name, IP address, and an ID or number assigned to your account.
            </ListItem>
            <ListItem>
              Customer records, such as billing and shipping address, and credit
              or debit card data.
            </ListItem>
            <ListItem>
              Commercial information, such as products or services history and
              purchases.
            </ListItem>
          </List>
          <Text mb={4}>
            For more information on information we collect, including the
            sources we receive information from, review the “Information We
            Collect” section. We collect and use these categories of personal
            information for the business purposes described in the “Collection
            and Use of Information” section, including to provide and manage our
            Service.
          </Text>

          <Heading as="h4" size="md" mb={2}>
            Right to Know and Delete
          </Heading>
          <Text mb={4}>
            You have rights to delete your personal information we collected and
            know certain information about our data practices in the preceding
            12 months. In particular, you have the right to request the
            following from us:
          </Text>
          <List mb={4} spacing={2}>
            <ListItem>
              The categories of personal information we have collected about
              you;
            </ListItem>
            <ListItem>
              The categories of sources from which the personal information was
              collected;
            </ListItem>
            <ListItem>
              The categories of personal information about you we disclosed for
              a business purpose or sold;
            </ListItem>
            <ListItem>
              The categories of third parties to whom the personal information
              was disclosed for a business purpose or sold;
            </ListItem>
            <ListItem>
              The business or commercial purpose for collecting or selling the
              personal information; and
            </ListItem>
            <ListItem>
              The specific pieces of personal information we have collected
              about you.
            </ListItem>
          </List>
          <Text mb={4}>
            To exercise any of these rights, please contact us using the details
            provided in this privacy policy.
          </Text>

          {/* Shine the Light */}
          <Heading as="h4" size="md" mb={2}>
            Shine the Light
          </Heading>
          <Text mb={4}>
            In addition to the rights discussed above, you have the right to
            request information from us regarding the manner in which we share
            certain personal information as defined by applicable statute with
            third parties and affiliates for their own direct marketing
            purposes.
          </Text>
          <Text mb={4}>
            To receive this information, send us a request using the contact
            details provided in this privacy policy. Requests must include
            “Privacy Rights Request” in the first line of the description and
            include your name, street address, city, state, and ZIP code.
          </Text>

          {/* Contact Us */}
          <Heading as="h3" size="md" mb={4}>
            Contact Us
          </Heading>
          <Text mb={4}>
            For any questions or concerns regarding your privacy, you may
            contact us using the following details:
          </Text>
          <Text mb={4}>
            Customer Service
            <br />
            info@imongu.com
          </Text>
        </Box>
      </CustomScrollbar>
    </Box>
  );
};

export default PrivacyPolicy;
