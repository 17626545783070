import React from "react";
import { PieChart, Pie, Cell } from "recharts";

// const COLORS = ["#0088FE", "#FFFD01", "#FFAC1C", "#FF0A2F"];
const COLORS = ["#FF2934", "#FF8400", "#FFF000", "#53DC0D"];

const RADIAN = Math.PI / 180;
const renderCustomizedLabel = ({
  cx,
  cy,
  midAngle,
  innerRadius,
  outerRadius,
  percent,
  index,
}) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);
  const percentage = (percent * 100).toFixed(0);
  if (percentage > 0) {
    return (
      <text
        x={x}
        y={y}
        fill="#204579"
        textAnchor="middle"
        dominantBaseline="middle"
        fontWeight="600"
      >
        {`${percentage}%`}
      </text>
    );
  }
};

const PieChartComponent = ({ title, dataApi, count, type }) => {
  // Sample data
  const data = [
    { name: "Above 70%", students: 400 },
    { name: "50-70 %", students: 700 },
    { name: "30-50 %", students: 200 },
    { name: "0-30 %", students: 1000 },
  ];

  const renderChart = () => {
    return (
      <PieChart
        width={200}
        height={200}
        style={{
          padding: "10px",
          boxShadow: "0px 0px 14.4px 0px rgba(187, 202, 213, 0.7)",
          borderRadius: "50%",
          cursor : 'pointer'
        }}
      >
        {/* Main Pie component */}
        <Pie
          data={dataApi || data}
          cx="50%"
          cy="50%"
          labelLine={false}
          label={renderCustomizedLabel}
          outerRadius={100}
          fill="#8884d8"
          dataKey="count"
          innerRadius={28}
          onClick={() => {}}
          style={{
            outline: "none",
            boxShadow: "inset 0 0 0 11px black",
            MozBoxShadow: "inset 0 0 0 11px black",
            WebkitBoxShadow: "inset 0 0 0 11px black",
          }}
        >
          {/* Render pie chart cells */}
          {data?.map((entry, index) => (
            <Cell
              style={{
                outline: "none",
                boxShadow: "inset 0px -4px 10px rgba(0, 0, 0, 0.25)",
              }}
              key={`cell-${index}`}
              fill={COLORS[index % COLORS.length]}
              stroke="#fff"
              strokeWidth={1}
            />
          ))}
        </Pie>

        {/* Transparent circle as a mask */}
        <circle
          cx="100"
          cy="100"
          r="28"
          fill="white"
          stroke="#F6F1F1"
          strokeWidth="5"
          filter="url(#filter0_d_731_1146)"
        />

        {/* Add the SVG icon */}
        <svg
          x="43%"
          y="41%"
          width="30"
          height="36"
          viewBox="0 0 15 18"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          // style={{ position: "absolute", transform: "translate(-50%, -50%)" }}
        >
          <path
            d="M2.83333 16.4583C1.44111 16.4583 0.3125 15.3297 0.3125 13.9375C0.3125 13.35 0.46316 12.8215 0.764479 12.3521C1.06581 11.8827 1.4846 11.5223 2.02083 11.2708C3.0625 10.8264 4.01024 10.5 4.86404 10.2917C5.71785 10.0833 6.5966 9.97917 7.50031 9.97917C8.43066 9.97917 9.31597 10.0799 10.1562 10.2813C10.9965 10.4826 11.9375 10.8125 12.9792 11.2708C13.5117 11.5045 13.9331 11.8566 14.2432 12.327C14.5533 12.7974 14.7083 13.3339 14.7083 13.9364C14.7083 15.3292 13.5792 16.4583 12.1864 16.4583H2.83333ZM7.50517 8.70833C6.50172 8.70833 5.6875 8.39583 5.0625 7.77083C4.4375 7.14583 4.125 6.32986 4.125 5.32292C4.125 4.31597 4.43781 3.5 5.06342 2.875C5.68904 2.25 6.50154 1.9375 7.50092 1.9375C8.50031 1.9375 9.31597 2.25022 9.94792 2.87565C10.5799 3.50108 10.8958 4.31336 10.8958 5.3125C10.8958 6.32639 10.5816 7.14583 9.95308 7.77083C9.32458 8.39583 8.50861 8.70833 7.50517 8.70833Z"
            fill="#427F93"
          />
        </svg>
      </PieChart>
    );
  };

  return (
    <div className="flex justify-center gap-8 font-poppins">
      <div className="flex justify-center flex-col gap-2 ">
        <h2 className="text-lg font-bold text-center ">{title}</h2>
        {count ? (
          <div className="flex gap-x-4 items-center justify-between  ">
            {renderChart()}
          </div>
        ) : (
          <div style={{ height: '200px',width: '200px' }}>
          <div className="w-full flex justify-center items-center mt-12 text-center">
            No data found, <br /> Please create a {type}
          </div>
          </div>
        )}
      </div>
      {count !==0 && (
        <div className=" flex justify-end">
          <div className="flex flex-col p-0.5 gap-2 mt-auto mb-14 ">
            {data?.reverse()?.map((entry, index) => (
              <div className="flex items-center gap-x-2 text-xs" key={index}>
                <span
                  className="p-1.5 rounded-full font-medium text-xs"
                  style={{ backgroundColor: COLORS[index % COLORS.length] }}
                  key={index}
                />
                {entry.name}
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default PieChartComponent;
