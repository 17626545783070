import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  Flex,
  Box,
  Text,
  VStack,
  Td,
  Thead,
  Tr,
  Th,
  Tbody,
  Table,
} from "@chakra-ui/react";
import { ChevronDownIcon } from "@chakra-ui/icons";
import ShowChartIcon from "@mui/icons-material/ShowChart";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { getAvatarColor, getInitials } from "../../utils/helpers";

const getStatusColor = (overallGain) => {
  if (overallGain === 0) return { color: "gray.100", status: "No Status" };
  if (overallGain > 0 && overallGain <= 25)
    return { color: "red.100", status: "Off Track" };
  if (overallGain > 25 && overallGain <= 50)
    return { color: "yellow.100", status: "Behind" };
  if (overallGain > 50 && overallGain <= 75)
    return { color: "blue.100", status: "In Progress" };
  if (overallGain > 75 && overallGain <= 100)
    return { color: "green.100", status: "On Track" };

  return { color: "gray", status: "Unknown" };
};

const ModalContentComponent = ({ isOpen, onClose, selectedNode }) => {
  console.log(selectedNode, "sele");
  const Sessions = useSelector((state) => state.user?.sessionList);
  const [selectedQuarter, setSelectedQuarter] = useState(Sessions[0]?.value);
  const [openAccordions, setOpenAccordions] = useState({});

  const toggleAccordion = (index) => {
    setOpenAccordions((prev) => ({
      ...prev,
      [index]: !prev[index],
    }));
  };

  console.log(Sessions);

  const filteredSession = selectedNode?.session_goals?.find(
    (session) => session.session === selectedQuarter
  );

  console.log("sess", selectedNode, selectedQuarter, filteredSession);

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="2xl">
      <ModalOverlay />
      <ModalContent borderRadius="md" overflow="hidden" height="80vh">
        <ModalHeader display="flex" alignItems="center">
          <Box as="div" display="flex" alignItems="center" mr="4">
            {selectedNode?.user_profile?.profile_image ? (
              <Box
                as="img"
                src={selectedNode?.user_profile.profile_image}
                alt="Profile"
                boxSize="50px"
                borderRadius="full"
                mr="4"
              />
            ) : (
              <svg width="50" height="50" viewBox="0 0 100 100">
                <circle
                  cx="50"
                  cy="50"
                  r="50"
                  fill={getAvatarColor(selectedNode?.user_profile?.username)}
                />
                <text
                  x="50"
                  y="50"
                  textAnchor="middle"
                  dy=".3em"
                  fontSize="24"
                  fill="#fff"
                >
                  {getInitials(selectedNode?.user_profile?.username)}
                </text>
              </svg>
            )}
          </Box>

          <Box>
            <Text fontWeight="bold" fontSize="lg">
              {selectedNode?.user_profile?.username}
            </Text>
            <Text color="gray.500">{selectedNode?.role_name}</Text>
          </Box>
        </ModalHeader>
        <ModalCloseButton />

        <ModalBody
          display="flex"
          p="0"
          overflowY="auto"
          height="calc(100% - 56px)" 
          sx={{
            /* Custom scrollbar styles */
            "&::-webkit-scrollbar": {
              width: "8px",
            },
            "&::-webkit-scrollbar-track": {
              backgroundColor: "#f1f1f1",
            },
            "&::-webkit-scrollbar-thumb": {
              backgroundColor: "orange.400",
              borderRadius: "4px",
            },
            "&::-webkit-scrollbar-thumb:hover": {
              backgroundColor: "#555",
            },
          }}
        >
          <VStack
            bg="gray.50"
            w="20%"
            spacing="4"
            align="stretch"
            py="6"
            borderRight="1px solid"
            height="100%"
            borderColor="gray.200"
            position="sticky"
            top="0"
            sx={{
              "&::-webkit-scrollbar": {
                width: "8px",
              },
              "&::-webkit-scrollbar-track": {
                backgroundColor: "#f1f1f1",
              },
              "&::-webkit-scrollbar-thumb": {
                backgroundColor: "orange.400",
                borderRadius: "4px",
              },
              "&::-webkit-scrollbar-thumb:hover": {
                backgroundColor: "#555",
              },
            }}
          >
            {Sessions?.map((q, index) => (
              <Box
                key={index}
                w="full"
                textAlign="center"
                py="4"
                px="2"
                bg={selectedQuarter === q.value ? "orange.400" : "white"}
                color={selectedQuarter === q.value ? "white" : "black"}
                borderRadius="md"
                fontWeight="semibold"
                fontSize="sm"
                cursor="pointer"
                onClick={() => setSelectedQuarter(q.value)}
                boxShadow={selectedQuarter === q.value ? "md" : "none"}
                transition="all 0.2s ease-in-out"
                _hover={{
                  bg: selectedQuarter === q.value ? "orange.400" : "gray.100",
                  transform: "scale(1.02)",
                }}
              >
                {q.label}
                <Text
                  fontSize="xs"
                  color={
                    selectedQuarter === q.value ? "whiteAlpha.800" : "gray.600"
                  }
                >
                  {q.year}
                </Text>
              </Box>
            ))}
          </VStack>

          {/* Right Content Area for Goals */}
          <Box flex="1" p="4">
            <Text fontSize="md" mb="4">
              Goals
            </Text>
            <VStack spacing="4" align="stretch">
              <Box>
                {filteredSession?.goals?.length > 0 ? (
                  filteredSession.goals.map((goal, goalIndex) => (
                    <Box
                      key={goal?.goal_id}
                      bg="gray.50"
                      p="4"
                      borderRadius="md"
                      mb="4"
                    >
                      <Flex align="center" justify="space-between">
                        <Flex align="center">
                          <Box as="span" mr="3" color="orange.400">
                            <button>
                              <img
                                src="/assets/images/goal_admin.png"
                                alt="Goal Admin"
                                style={{ width: "28px", height: "28px" }}
                              />
                            </button>
                          </Box>
                          <Text fontSize="sm" fontWeight="medium">
                            {goal.title}
                          </Text>
                        </Flex>
                        <ChevronDownIcon
                          color="gray.500"
                          cursor="pointer"
                          onClick={() => toggleAccordion(goalIndex)}
                        />
                      </Flex>
                      {openAccordions[goalIndex] && (
                        <Box mt="4">
                          <Table variant="simple">
                            <Tbody>
                              {goal?.okrs?.length > 0 ? (
                                goal.okrs.map((okr) => (
                                  <React.Fragment key={okr?.okr_id}>
                                    <Box className="bg-white mb-3 rounded-md">
                                      <div
                                        style={{
                                          display: "flex",
                                          alignItems: "center",
                                          justifyContent: "space-between",
                                          width: "100%",
                                          padding: "8px",
                                        }}
                                      >
                                        <div
                                          style={{
                                            flex: 1,
                                            width: "100%",
                                            textAlign: "left", // Center-aligns the title
                                          }}
                                        >
                                          <Text
                                            fontWeight="medium"
                                            color="gray.700"
                                          >
                                            {okr?.title}
                                          </Text>
                                        </div>

                                        <div
                                          style={{
                                            display: "flex",
                                            alignItems: "center",
                                          }}
                                        >
                                          <ShowChartIcon fontSize="small" />
                                          <Text
                                            style={{
                                              marginLeft: "2px",
                                              fontSize: "0.875rem",
                                              color: "gray.600",
                                            }}
                                          >
                                            Completion
                                          </Text>
                                          <Box
                                            style={{
                                              backgroundColor: "#FFF8F0",
                                              color: "#F27E0D",
                                              padding: "8px",
                                              borderRadius: "8px",
                                              fontSize: "0.875rem",
                                              marginLeft: "10px",
                                            }}
                                          >
                                            {okr?.average_gain}%
                                          </Box>
                                        </div>
                                      </div>

                                      <Table variant="simple" width="100%">
                                        <Thead>
                                          <Tr>
                                            <Th>Key Results</Th>
                                            <Th>Status</Th>
                                            <Th isNumeric>Overall Gain</Th>
                                          </Tr>
                                        </Thead>
                                        <Tbody>
                                          {okr.key_results.length > 0 ? (
                                            okr.key_results.map((kr) => {
                                              const { color, status } =
                                                getStatusColor(kr.overall_gain); // Destructure color and status outside of JSX

                                              return (
                                                <Tr
                                                  className="w-full"
                                                  key={kr?.key_id}
                                                >
                                                  <Td
                                                    className="w-full"
                                                    border="1px"
                                                    borderColor="gray.200"
                                                    padding="8px" // Adjusted padding for better readability
                                                  >
                                                    <Flex align="center">
                                                      <Box
                                                        as="span"
                                                        color="blue.400"
                                                        mr="2"
                                                      >
                                                        ◆
                                                      </Box>
                                                      <Text>{kr?.title}</Text>
                                                    </Flex>
                                                  </Td>
                                                  <Td
                                                    border="1px"
                                                    borderColor="gray.200"
                                                    padding="8px" // Adjusted padding for better readability
                                                  >
                                                    <Box
                                                      bg={color}
                                                      borderRadius="md"
                                                    >
                                                      <Box
                                                        borderRadius="xl"
                                                        p="2"
                                                        color="gray.600"
                                                        fontSize="12px"
                                                        textAlign={"center"}
                                                      >
                                                        {status}
                                                      </Box>
                                                    </Box>
                                                  </Td>
                                                  <Td
                                                    textAlign="center"
                                                    color="gray.600"
                                                    backgroundColor={color}
                                                    border="1px"
                                                    borderColor="gray.200"
                                                    fontSize="12px"
                                                    padding="8px" // Adjusted padding for better readability
                                                  >
                                                    {kr?.overall_gain}%
                                                  </Td>
                                                </Tr>
                                              );
                                            })
                                          ) : (
                                            <Tr>
                                              <Td
                                                colSpan={3}
                                                textAlign="center"
                                                color="gray.500"
                                                padding="12px"
                                              >
                                                No Key Results Available
                                              </Td>
                                            </Tr>
                                          )}
                                        </Tbody>
                                      </Table>
                                    </Box>
                                  </React.Fragment>
                                ))
                              ) : (
                                <Tr>
                                  <Td
                                    colSpan={3}
                                    textAlign="center"
                                    color="gray.500"
                                  >
                                    No Objectives Available
                                  </Td>
                                </Tr>
                              )}
                            </Tbody>
                          </Table>
                        </Box>
                      )}
                    </Box>
                  ))
                ) : (
                  <Text textAlign="center" color="gray.500">
                    No Goals Available
                  </Text>
                )}
              </Box>
            </VStack>
          </Box>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default ModalContentComponent;
