import React from "react";
import { Avatar, Progress } from "antd";
import {
  getAvatarColor,
  getFormatedDateAndMonth,
  getInitials,
} from "../../utils/helpers";
import { BiGlobe } from "react-icons/bi";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { handleOpenKeyresultDrawer } from "../../stores/slices/okrSlice";
const KeyresultsReportTable = ({ keyResultReports }) => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state?.user);
  const Sessions = useSelector((state) => state.user?.sessionList);

  const handleOpenDrawerWithKey = (key_id) => {
    if (user?.roleAccess?.["Key-Results"]?.View) {
      dispatch(handleOpenKeyresultDrawer(key_id));
    }
  };

  return (
    <div className="w-full overflow-x-auto">
      <table className="table-auto w-full border-collapse">
        <thead className="bg-header text-white sticky top-0">
          <tr>
            <th className="px-4 py-2 text-center">Name</th>
            <th className="px-4 py-2 text-center">Owner(s)</th>
            <th className="px-4 py-2 text-center">Progress</th>
            <th className="px-4 py-2 text-center">Sessions</th>
            <th className="px-4 py-2 text-center">Description</th>
            <th className="px-4 py-2 text-center">Deadline</th>
          </tr>
        </thead>
        <tbody>
          {keyResultReports?.map((item, index) => (
            <tr key={index} className="border-b hover:bg-gray-50">
              {/* Name */}
              <td className="px-4 py-2 text-center">
                <Link
                  onClick={() => handleOpenDrawerWithKey(item?.key_id)}
                  className="text-blue-500"
                >
                  {item?.title}
                </Link>
              </td>

              {/* Owners */}
              <td className="px-4 py-2 text-center">
                <Avatar.Group
                  maxCount={2}
                  size="small"
                  maxPopoverTrigger="click"
                  maxStyle={{
                    color: "#f56a00",
                    backgroundColor: "#fde3cf",
                  }}
                >
                  {item?.owners?.map((owner, index) =>
                    owner?.type === "user" ? (
                      <div
                        key={index}
                        className="w-8 h-8 text-sm border border-black rounded-full flex items-center justify-center text-white"
                        style={{
                          backgroundColor: owner?.profile_image
                            ? "initial"
                            : getAvatarColor(owner?.name),
                        }}
                      >
                        {owner?.profile_image ? (
                          <img
                            src={owner?.profile_image}
                            alt="Profile"
                            className="w-full h-full object-cover rounded-full"
                          />
                        ) : (
                          getInitials(owner.name)
                        )}
                      </div>
                    ) : (
                      <span
                        key={index}
                        className="p-1 bg-gray-600 rounded-full"
                      >
                        <BiGlobe className="text-gray-300 text-xs" />
                      </span>
                    )
                  )}
                </Avatar.Group>
              </td>

              {/* Progress */}
              <td className="px-4 py-2 text-center">
                <Progress
                  type="circle"
                  size={50}
                  percent={item?.overall_gain}
                  strokeWidth={10}
                  strokeColor={
                    item?.overall_gain >= 0 && item?.overall_gain <= 29
                      ? "#FF2934"
                      : item?.overall_gain >= 30 && item?.overall_gain <= 49
                      ? "#FF8400"
                      : item?.overall_gain >= 50 && item?.overall_gain <= 69
                      ? "#FFF000"
                      : "#53DC0D"
                  }
                  trailColor="#f0f0f0"
                  format={(percent) => (
                    <span style={{ fontWeight: "medium", color: "black" }}>
                      {percent}%
                    </span>
                  )}
                />
              </td>

              {/* Sessions */}
              <td className="px-4 py-2 text-center">
                {Sessions?.find(
                  (items) => items?.value === item?.session?.label
                )}
              </td>

              {/* Description */}
              <td className="px-4 py-2 text-left">
                <div
                  className="p-2 border rounded"
                  style={{
                    border: "0.96px solid rgba(168, 175, 178, 0.72)",
                  }}
                >
                  {item?.description}
                </div>
              </td>

              {/* Deadline */}
              <td className="px-4 py-2 text-center">
                {getFormatedDateAndMonth(item?.deadline)}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};
export default KeyresultsReportTable;
