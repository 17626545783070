import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { updateTitleData } from "../../stores/slices/userAuthSlice";
import AdminView from "./AdminView";
import NonAdmin from "./nonadmin";
import { useAppTouchBaseContext } from "../../components/StepByStepGuide/context";

const Touchbase = () => {
  const dispatch = useDispatch();
  const {
    setTouchBaseState,
    TouchBasestate: { run, selectedtab },
  } = useAppTouchBaseContext();
  const [selectedTab, setSelectedTab] = useState("Non Admin");
  const [isDropdown, setIsDropdown] = useState(false);

  const handleResize = () => {
    setIsDropdown(window.innerWidth < 920); // Toggle dropdown based on screen size
  };

  React.useEffect(() => {
    handleResize(); // Initial check
    window.addEventListener("resize", handleResize); // Listen for screen resize
    return () => window.removeEventListener("resize", handleResize); // Cleanup
  }, []);

  const tabs = ["Non Admin", "Admin"];

  useEffect(() => {
    if (selectedtab) {
      setSelectedTab(selectedtab);
    }
    if (run) {
      console.log("Starting the tour");
    }
  }, [selectedtab, run]);

  useEffect(() => {
    dispatch(
      updateTitleData({
        title: "TouchBase",
        ClickLink: "company / Touchbase",
      })
    );
  }, [dispatch]);

  return (
    <div className="w-screen md:w-full h-full md:h-screen p-4 font-poppins  ">
      {isDropdown ? (
        <div className="relative mb-4">
          <select
            value={selectedTab}
            onChange={(e) => setSelectedTab(e.target.value)}
            className="p-2 border border-gray-300 rounded"
            style={{
              width: window.innerWidth < 920 ? "100vw" : "300px", // Full width on small screens, fixed on larger screens
              maxWidth: "100%", // Ensures it doesn't overflow the screen
            }}
          >
            {tabs?.map((tab) => (
              <option key={tab} value={tab}>
                {tab}
              </option>
            ))}
          </select>
        </div>
      ) : (
        <div className="flex items-center space-x-16 px-5">
          {tabs?.map((tab) => (
            <h1
              key={tab}
              onClick={() => setSelectedTab(tab)}
              className={`relative cursor-pointer ${
                selectedTab === tab ? "text-orange-500 font-sans" : ""
              }`}
            >
              {tab}
              {selectedTab === tab && (
                <span className="absolute left-0 bottom-[-10px] w-full h-[1px] bg-orange-500 transition-all duration-300 ease-in-out"></span>
              )}
            </h1>
          ))}
        </div>
      )}
      <div className="relative mt-2 ">
        <div className="border-b-2 border-gray-300 mb-8"></div>
        {/* <div
          className={`absolute bottom-0 transition-all duration-300 ease-in-out ${
            selectedTab === "Non-Admin" ? "left-0 w-32" : "left-36 w-20"
          } border-b-2 border-orange-500 `}
        ></div> */}
      </div>
      <div className="fourth_touchbase_tour">
        {selectedTab === "Non Admin" && <NonAdmin />}
        {selectedTab === "Admin" && (
          <div
          className="flex flex-col p-4 h-screen md:h-[75vh]"
          style={{
            backgroundColor: "#F7F8FA",
            borderRadius: "16px",
            width: "100%",
          }}
        >
            <AdminView />
          </div>
        )}
      </div>
    </div>
  );
};

export default Touchbase;
