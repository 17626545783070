import React from "react";
import CustomProgressBar from "./CustomProgressBar";
import { useAppContext } from "../../StepByStepGuide/context";
import { PieChart, Pie, Cell } from "recharts";
import { useMount } from "react-use";

const StrategyWidgits = ({ reportData }) => {
  const {
    setState,
    state: { tourActive },
  } = useAppContext();

  useMount(() => {
    if (tourActive) {
      setState({ run: true, stepIndex: 4 });
    }
  });

  const valuenew = reportData?.avgConfidence === "AT HIGH RISK" ? 80 : 180;

  const RADIAN = Math.PI / 180;
  const data = [
    {
      name: "A",
      value: valuenew,
      gradient:
        "linear-gradient(90deg, rgba(11, 20, 252, 0.77) 0%, rgba(255, 1, 1, 1) 50%, rgba(255, 1, 1, 1) 100%)",
    },
  ];
  const gradientColors = [
    { offset: "0%", color: "rgba(11, 20, 252, 1)" },
    { offset: "80%", color: "rgba(255, 1, 1, 1)" },
  ];
  const cx = 150;
  const cy = 200;
  const iR = 50;
  const oR = 100;
  const value = 0;

  const needle = (value, data, cx, cy, iR, oR, color) => {
    console.log(value, "value");
    let total = 0;
    data.forEach((v) => {
      total += v.value;
    });
    const ang = 180.0 * (1 - value / total);
    const length = (iR + 2 * oR) / 3;
    const sin = Math.sin(-RADIAN * ang);
    const cos = Math.cos(-RADIAN * ang);
    const baseRadius = 20; // Adjust the base radius as needed
    const needleRadius = 10; // Adjust the needle radius as needed
    const x0 = cx + 5;
    const y0 = cy + 5;
    const xba = x0 + needleRadius * sin;
    const yba = y0 - needleRadius * cos;
    const xbb = x0 - needleRadius * sin;
    const ybb = y0 + needleRadius * cos;
    const xp = x0 + length * cos;
    const yp = y0 + length * sin;

    return [
      <defs>
        <filter id="shadow" x="-20%" y="-20%" width="140%" height="140%">
          <feGaussianBlur in="SourceAlpha" stdDeviation="2" />
          <feOffset dx="0" dy="2" result="offsetblur" />
          <feFlood floodColor="rgba(0,0,0,0.25)" />
          <feComposite in2="offsetblur" operator="in" />
          <feMerge>
            <feMergeNode />
            <feMergeNode in="SourceGraphic" />
          </feMerge>
        </filter>
      </defs>,

      <path
        d={`M${xba} ${yba} L${xbb} ${ybb} L${xp} ${yp} L${xba} ${yba}`}
        stroke="none"
        fill="white"
        filter="url(#shadow)"
      />,
      <defs>
        <filter id="drop-shadow" x="-20%" y="-20%" width="140%" height="140%">
          <feDropShadow
            dx="0"
            dy="4"
            stdDeviation="4"
            floodColor="rgba(0, 0, 0, 0.25)"
          />
        </filter>
      </defs>,
      <circle
        cx={x0}
        cy={y0}
        r={baseRadius}
        fill="white"
        stroke="rgba(236, 236, 236, 1)"
        strokeWidth="0.5"
        filter="url(#drop-shadow)"
      />,
      <circle cx={x0} cy={y0} r={needleRadius} fill={color} stroke="none" />,
    ];
  };

  return (
    <div className="flex flex-col gap-2 items-start fifth_tour">
      <div className="w-full grid grid-cols-1 sm:grid-cols-2 gap-5 mt-2">
        <div
          className="flex flex-col border p-4 rounded"
          style={{
            boxShadow: "0px 4px 20px 0px #C3D7EB",
          }}
        >
          <h4
            className="text-2xl font-medium"
            style={{
              color: "#4195B1",
            }}
          >
            Average Progress
          </h4>
          <span className="text-base font-medium text-basic">
            Average Progress of {reportData?.keyResult} Key Results
          </span>
          <div className="h-48 w-full flex justify-center">
            <CustomProgressBar percentage={reportData?.overallGainAvg} />
          </div>
        </div>

        <div
          className="flex flex-col border p-4 rounded"
          style={{
            boxShadow: "0px 4px 20px 0px #C3D7EB",
          }}
        >
          <h4
            className="text-2xl font-medium"
            style={{
              color: "#4195B1",
            }}
          >
            Average Confidence
          </h4>
          <span className="text-base font-medium text-basic">
            Average Progress of {reportData?.keyResult} Key Results
          </span>
          <div className="h-48 w-full flex justify-center items-center">
            <p
              className="text-lg text-right font-semibold"
              style={{
                color: "#0A6A9C",
              }}
            >
              Low
            </p>
            <PieChart
              className="w-full h-full"
              width={300}
              height={300}
              style={{ transition: "all 5s ease-in-out" }}
            >
              <Pie
                dataKey="value"
                startAngle={180}
                endAngle={0}
                data={data}
                cx={cx}
                cy={cy}
                innerRadius={iR}
                outerRadius={oR}
                stroke="none"
              >
                {data?.map((entry, index) => (
                  <Cell key={index} fill={`url(#color-${index})`} />
                ))}
              </Pie>
              <defs>
                {data?.map((entry, index) => (
                  <linearGradient
                    key={index}
                    id={`color-${index}`}
                    x1="0"
                    y1="0"
                    x2="1"
                    y2="0"
                  >
                    {gradientColors?.map((color, index) => (
                      <stop
                        key={index}
                        id={`cell-${index}`}
                        offset={color.offset}
                        stopColor={color.color}
                      />
                    ))}
                  </linearGradient>
                ))}
              </defs>
              {needle(value, data, cx, cy, iR, oR, "#000")}
            </PieChart>
            <p
              className="text-lg text-left font-semibold"
              style={{
                color: "#0A6A9C",
              }}
            >
              High
            </p>
          </div>
          <p
            className="text-lg text-center font-semibold"
            style={{
              color: "#0A6A9C",
            }}
          >
            Risk
          </p>
        </div>
      </div>
    </div>
  );
};

export default StrategyWidgits;
