import React, { useEffect, useState } from "react";

import { Modal, Select } from "antd";
import { Tables } from "../../components/People/Employees/Tables";
import { baseURL } from "../../utils/config";
import { useDispatch, useSelector } from "react-redux";
import { BiGlobe } from "react-icons/bi";
import { ToastContainer, toast } from "react-toastify";
import LoadingPage from "../../components/global/LoadingPage";
import { useNavigate } from "react-router-dom";
import {
  handleUpdatePlan,
  updateTitleData,
} from "../../stores/slices/userAuthSlice";
import { useAppContext } from "../../components/StepByStepGuide/context";
import { useMount } from "react-use";
import { handleCloseTeamDrawer } from "../../stores/slices/okrSlice";
import { ChevronDownIcon } from "@chakra-ui/icons";
import { Button, Spinner } from "@chakra-ui/react";
import FilterEmployees from "./FilterEmployee";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
  Portal,
} from "@chakra-ui/react";
import { api } from "../../components/global/api";
import CustomScrollbar from "../../components/global/CustomScrollBar";

const validateEmail = (email) => {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(email);
};

const assign_role = ["user", "admin"];

export const Employees = () => {
  // const [showLoader, setLoader] = useState(true);

  const [formData, setformData] = useState({
    email: "",
    // assign_team: "",
    assign_role: "",
    report_to: "",
    user: "",
    check_box: "",
  });
  const [teams, setTeams] = useState([]);
  const currentUser = useSelector((state) => state.user?.currentUser);
  const user = useSelector((state) => state?.user);
  const currentPlan = user?.currentPlan;
  const okrSlice = useSelector((state) => state?.okr);
  const [loadingInvite, setLoadingInvite] = useState(false);
  const [loadingPage, setLoadingPage] = useState(false);
  const [filterModal, setFilterModal] = useState(false);
  const [roleError, setRoleError] = useState(false);
  const [reportError, setReportError] = useState(false);
  const [owners, setOwners] = useState(false);
  const [popoverOpen, setPopoverOpen] = useState(false);
  const [assignRole, setAssignRole] = useState([]);
  const [reportRoles, setReportRoles] = useState([]);

  const [data, setdata] = useState([]);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const fetchRole = async () => {
    try {
      const response = await api.get(baseURL + "/role/", {
        company_id: currentUser?.employees[user?.currentCompany]?.company_id,
      });
      setAssignRole(response?.data);
    } catch (error) {
      console.log(error);
    }
  };

  if (currentPlan?.plan_name === "Free" && !currentPlan?.free_trial_status) {
    navigate("/settings?tab=Billing");
  }

  const {
    setState,
    state: { tourActive },
  } = useAppContext();

  useMount(() => {
    if (tourActive) {
      setState({ run: true, stepIndex: 6 });
      // }, 1200);
    }
  });

  const [formDataFilter, setformDatafilter] = useState({
    email: "",
    name: "",
    team_owner: [],
  });

  const fetchData = async () => {
    try {
      setLoadingPage(true);
      const currentEmployee = currentUser?.employees;
      let team_ids2 = [];
      formDataFilter?.team_owner?.forEach((item) => {
        let typeArr = item.split(" ");
        team_ids2.push(typeArr[0]);
      });
      let company_id;
      let user_id;
      const params = {
        name: formDataFilter?.name,
        email: formDataFilter?.email,
        team_ids: team_ids2,
      };
      if (currentEmployee && currentEmployee?.length) {
        company_id = currentEmployee[user?.currentCompany]?.company_id;
        user_id = currentEmployee[user?.currentCompany]?.user_id;
      }
      const response = await api.get(baseURL + "/employee_details/", {
        company_id: company_id,
        fields: JSON.stringify(params),
      });
      setdata(response.data);
    } catch (error) {
      //  setError(error);
      console.log(error);
    } finally {
      setLoadingPage(false);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const currentEmployee = currentUser?.employees;
        let company_id;
        if (currentEmployee && currentEmployee?.length) {
          company_id = currentEmployee[user?.currentCompany]?.company_id;
        }
        const response = await api.get(baseURL + "/assignowners/", {
          company_id: company_id,
        });
        setOwners(response.data);
      } catch (error) {
        console.log(error);
      }
    };

    fetchData();
  }, []);

  const getTeams = async () => {
    try {
      const currentEmployee = currentUser?.employees;
      let company_id;
      if (currentEmployee && currentEmployee?.length) {
        company_id = currentEmployee[user?.currentCompany]?.company_id;
      }
      const response = await api.get(baseURL + "/create-team/", {
        company_id: company_id,
      });
      setTeams(response?.data);
    } catch (error) {
      //  setError(error);
      console.log(error);
    }
  };
  useEffect(() => {
    fetchRole();
    fetchData();
    getTeams();
  }, []);

  const [emailError, setEmailError] = useState(false);

  function changeHandeler(event) {
    const { name, value } = event.target;
    if (name === "email") {
      setEmailError(!validateEmail(value));
      setformData((prevFormData) => ({
        ...prevFormData,
        [name]: value,
        user: name === "email" ? value : prevFormData?.user,
      }));
    } else {
      setformData((prevFormData) => ({
        ...prevFormData,
        [name]: value,
      }));
    }
  }

  const getCurrentPlanData = async () => {
    try {
      const currentCompany = user?.currentUser?.employees[user?.currentCompany];
      const result = await api.get(baseURL + "/subscription/", {
        company_id: currentCompany?.company_id,
      });

      dispatch(handleUpdatePlan(result.data));
    } catch (err) {
      console.log(err);
    }
  };

  const handleOk = (e) => {
    e.preventDefault();
    dispatch(handleCloseTeamDrawer());
  };
  const handleCancel = () => {
    setRoleError(false);
    setformData({
      email: "",
      // assign_team: "",
      assign_role: "",
      report_to: "",
      user: "",
      check_box: "",
    });
    dispatch(handleCloseTeamDrawer());
  };

  const handleDeleteEmployee = async (employee_id) => {
    try {
      setLoadingPage(true);
      const currentCompany = user?.currentUser?.employees[user?.currentCompany];
      const res = await api.delete(baseURL + "/employee_details/", {
        company_id: currentCompany?.company_id,
        employee_id,
      });
      getCurrentPlanData();
      toast.success("Employee Deleted Successfully");
    } catch (error) {
      console.log(error);
      toast.error("Please Try Again");
    } finally {
      setLoadingPage(false);
      fetchData();
    }
  };
  const handleDeactivateEmployee = async (employee_id) => {
    try {
      setLoadingPage(true);
      const currentCompany = user?.currentUser?.employees[user?.currentCompany];
      const res = await api.delete(baseURL + "/employee_details/", {
        employee_id,
        company_id: currentCompany?.company_id,
        deactivate: "true",
      });
      getCurrentPlanData();
      toast.success("Employee Deactivate Successfully");
    } catch (error) {
      console.log(error);
      toast.error("Please Try Again");
    } finally {
      setLoadingPage(false);
      fetchData();
    }
  };

  const fetchReportRoles = async (e) => {
    console.log(e, "eee");
    try {
      const res = await api.post(baseURL + "/report-to/", {
        role_id: e,
        company_id: currentUser?.employees[user?.currentCompany]?.company_id,
      });
      setReportRoles(res?.data);
    } catch (error) {}
  };

  const handleActivateEmployee = async (id) => {
    // console.log(e, "eee");
    try {
      const res = await api.post(baseURL + "/employee_details/", {
        employee_id: id,
        company_id: currentUser?.employees[user?.currentCompany]?.company_id,
      });
      toast.success("Employee Activated Sucessfully");
      fetchData();
    } catch (error) {
      toast.error("Please Try Again");
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    // console.log(setformData());
    try {
      setLoadingInvite(true);
      if (!formData?.email) {
        return;
      }

      if (formData?.assign_role === "") {
        setRoleError(true);
        return;
      }
      if (formData?.report_to === "") {
        setReportError(true);
        return;
      }

      const res = await api.post(baseURL + "/send-email/", {
        email: formData?.email,
        employee_role_id: formData?.assign_role,
        report_to: formData?.report_to,
        // "assign_team":formData.assign_team,
        company_id: currentUser?.employees[user?.currentCompany]?.company_id,
        teams: formData?.assign_team,
      });
      setLoadingInvite(false);
      handleCancel();
      toast.success("Employee Invited Sucessfully");
      fetchData();
      getCurrentPlanData();
      setformData({
        email: "",
        // assign_team: "",
        assign_role: "",
        report_to: "",
        user: "",
        check_box: "",
      });
    } catch (error) {
      // console.log(error)
      setLoadingInvite(false);
      handleCancel();
      if (error?.response?.status == 404) {
        toast.error("Employee already exists in the company");
        setformData({
          email: "",
          // assign_team: "",
          assign_role: "",
          report_to: "",
          user: "",
          check_box: "",
        });
      } else {
        setformData({
          email: "",
          // assign_team: "",
          report_to: "",
          assign_role: "",
          user: "",
          check_box: "",
        });
        toast.error("Invite user failed. Please try again");
      }
    } finally {
      setTimeout(() => {
        toast.dismiss();
        setLoadingInvite(false);
        handleCancel();
        fetchData();
        getCurrentPlanData();
      }, 1000);
    }
  };

  const [visibleFields, setVisibleFields] = useState(["Name"]);

  const values = ["Name", "Email", "Team Owners"];
  const addField = (field) => {
    setVisibleFields((prevVisibleFields) => {
      if (!prevVisibleFields.includes(field)) {
        return [...prevVisibleFields, field];
      }
      return prevVisibleFields;
    });
  };

  const removeField = (field, name) => {
    setformDatafilter((prevFormData) => {
      const updatedFormData = { ...prevFormData };

      if (name === "team_owner") {
        updatedFormData[name] = [];
      } else {
        updatedFormData[name] = "";
      }

      return updatedFormData;
    });

    setVisibleFields((prevVisibleFields) => {
      const updatedVisibleFields = prevVisibleFields.filter((f) => f !== field);
      return updatedVisibleFields;
    });
  };

  useEffect(() => {
    dispatch(
      updateTitleData({
        title: "People",
        ClickLink: "Company / Employees",
      })
    );
    fetchData();
  }, [formDataFilter]);

  return (
    <div className="relative p-2 bg-white h-full flex flex-col gap-y-2">
      <div className="flex justify-end">
        <span className="flex justify-between gap-x-4">
          <Popover
            placement="bottom-end"
            isLazy
            lazyBehavior="keepMounted"
            onClose={() => setPopoverOpen(false)}
          >
            <PopoverTrigger>
              <span className="flex justify-center items-center relative">
                <Button
                  px={4}
                  py={2}
                  width={"100px"}
                  transition="all 0.2s"
                  borderRadius="md"
                  borderWidth="1px"
                  _focus={{ boxShadow: "outline" }}
                  backgroundColor="white"
                  boxShadow={popoverOpen ? "outline" : ""}
                  color="#3C93C2"
                  fontWeight={"normal"}
                  borderColor="#3C93C2"
                  _hover={{
                    bg: "#F7FAFC",
                    color: "#287199",
                    borderColor: "#287199",
                    boxShadow: "outline",
                  }}
                  className="relative"
                  onClick={() => setPopoverOpen(true)}
                >
                  <span className="flex justify-evenly items-center gap-x-2">
                    <svg
                      width="14"
                      height="14"
                      viewBox="0 0 14 14"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M7.00017 13.7C6.80017 13.7 6.6335 13.6334 6.50017 13.5C6.36683 13.3667 6.30017 13.2 6.30017 13V7.55005L1.02517 0.900049C0.925167 0.766715 0.912667 0.633382 0.987667 0.500049C1.06267 0.366715 1.17517 0.300049 1.32517 0.300049H12.6752C12.8252 0.300049 12.9377 0.366715 13.0127 0.500049C13.0877 0.633382 13.0752 0.766715 12.9752 0.900049L7.70017 7.55005V13C7.70017 13.2 7.6335 13.3667 7.50017 13.5C7.36683 13.6334 7.20017 13.7 7.00017 13.7Z"
                        fill="#0A4464"
                      />
                    </svg>
                    <p>Filters</p> <ChevronDownIcon />
                  </span>
                </Button>
              </span>
            </PopoverTrigger>
            <Portal>
              <PopoverContent>
                <div>
                  <FilterEmployees
                    filterModal={filterModal}
                    setformData={setformDatafilter}
                    formData={formDataFilter}
                    owners={owners}
                    values={values}
                    removeField={removeField}
                    addField={addField}
                    visibleFields={visibleFields}
                    setVisibleFields={setVisibleFields}
                  />
                </div>
              </PopoverContent>
            </Portal>
          </Popover>
        </span>
      </div>
      <CustomScrollbar>
        <div className="flex flex-col w-full h-full rounded-lg">
          <Tables
            fetchData={fetchData}
            data={data}
            handleDeleteEmployee={handleDeleteEmployee}
            handleDeactivateEmployee={handleDeactivateEmployee}
            handleActivateEmployee={handleActivateEmployee}
          />
          <LoadingPage loading={loadingPage} />
        </div>
      </CustomScrollbar>
      <Modal
        title=""
        open={okrSlice?.openTeamDrawer}
        onOk={handleOk}
        onCancel={handleCancel}
        width={650}
        footer={null}
      >
        <form className="p-6" onSubmit={handleSubmit}>
          <div className="mb-2">
            <h1 className="p-1 text-3xl font-bold text-black">Invite users</h1>
            <label className="p-1 text-gray-600">
              Enter one or more email addresses separated by a comma or space.
            </label>
          </div>
          <hr />

          <div className="grid items-center grid-cols-6 py-4 below-div gap-y-4 gap-x-4">
            <label className="col-span-2 font-semibold text-gray-600">
              Email*
            </label>
            <div className="col-span-4">
              <input
                className="w-full  p-4 text-sm border border-gray-300 rounded-lg"
                type="email"
                name="email"
                id="email"
                onChange={changeHandeler}
                value={formData?.email}
                required
              />
            </div>
            <label className="col-span-2 font-semibold text-gray-600 ">
              Assign role(s)*
            </label>

            <div className="col-span-4">
              <Select
                className="w-full "
                placeholder=""
                name="assign_role"
                id="assign_role"
                onChange={(e) => {
                  setRoleError(false);
                  setformData({ ...formData, assign_role: e, report_to: "" });
                  fetchReportRoles(e);
                }}
                value={formData?.assign_role}
              >
                {assignRole?.map((assign_role, index) => {
                  return (
                    <Select.Option
                      value={assign_role?.role_id}
                      key={index}
                      disabled={assign_role?.role_name === "Admin"}
                    >
                      <span className="capitalize">
                        {assign_role?.role_name}
                      </span>
                    </Select.Option>
                  );
                })}
              </Select>
              {roleError && (
                <p className="mt-1 text-red-500">Role is required</p>
              )}
            </div>
            <label className="col-span-2 font-semibold text-gray-600 ">
              Report To*
            </label>

            <div className="col-span-4">
              <Select
                className="w-full "
                placeholder=""
                name="report_to"
                id="report_to"
                onChange={(e) => {
                  setReportError(false);
                  setformData({ ...formData, report_to: e });
                }}
                value={formData?.report_to}
              >
                {reportRoles?.map((assign_role, index) => {
                  return (
                    <Select.Option value={assign_role?.employee_id} key={index}>
                      <span className="capitalize">
                        {assign_role?.role_name} , {assign_role?.name}
                      </span>
                    </Select.Option>
                  );
                })}
              </Select>
              {reportError && (
                <p className="mt-1 text-red-500">Report To field is required</p>
              )}
            </div>

            <label className="col-span-2 font-semibold text-gray-600">
              Assign team(s)
            </label>
            <div className="col-span-4 ">
              <Select
                name="members"
                id="members"
                onChange={(val) => {
                  setformData({ ...formData, assign_team: val });
                }}
                value={formData?.assign_team}
                mode="multiple"
                required
                className="w-full "
                placeholder="Select Member"
              >
                {teams?.map((team, index) => {
                  return (
                    <Select.Option
                      value={team?.team_id || team?.tema_id}
                      key={index}
                    >
                      <div className="flex items-center gap-x-2">
                        <span className="p-1 bg-gray-600 rounded-full">
                          <BiGlobe className="text-gray-300 text-xs" />
                        </span>
                        <span>{team?.team_name}</span>
                      </div>
                    </Select.Option>
                  );
                })}
              </Select>
            </div>
            <div className="flex items-center justify-end col-span-6 gap-x-4">
              <button
                className="px-4 py-2 border rounded-xl border-black hover:text-blue-500 hover:border-blue-500"
                onClick={(e) => {
                  e.preventDefault();
                  handleCancel();
                }}
              >
                Cancel
              </button>
              <button
                type="submit"
                className="px-4 py-2 border rounded-xl text-white bg-button hover:bg-button-hover"
                disabled={loadingInvite}
              >
                Invite User
                {loadingInvite && (
                  <Spinner size="sm" color="white" className="ml-2" />
                )}
              </button>
            </div>
          </div>
          {/* )} */}
        </form>
      </Modal>
      <ToastContainer />
    </div>
  );
};
