import React from "react";
import { Avatar, Progress } from "antd";
import { getAvatarColor, getInitials } from "../../utils/helpers";
import { BiGlobe } from "react-icons/bi";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { handleOpenOkrDrawer } from "../../stores/slices/okrSlice";

const OkrReportsTable = ({ okrReports }) => {
  const dispatch = useDispatch();
  const roleAccess = useSelector((state) => state?.user?.roleAccess);

  const handleOpenDrawerWithKey = (okr_id) => {
    if (roleAccess?.Objective?.View) {
      dispatch(handleOpenOkrDrawer(okr_id));
    }
  };

  return (
    <div className="overflow-x-auto w-full">
      <table className="table-auto border-collapse w-full">
        <thead className="bg-header text-white">
          <tr>
            <th className="px-4 py-2 text-left">Name</th>
            <th className="px-4 py-2 text-left">Owner(s)</th>
            <th className="px-4 py-2 text-left">Progress</th>
            <th className="px-4 py-2 text-left">Description</th>
          </tr>
        </thead>
        <tbody>
          {okrReports?.map((item, index) => (
            <tr
              key={index}
              className={`border-b ${
                index % 2 === 0 ? "bg-gray-50" : "bg-white"
              }`}
            >
              {/* Name */}
              <td className="px-4 py-2">
                <Link
                  className="text-blue-600 underline"
                  onClick={() => handleOpenDrawerWithKey(item?.okr_id)}
                >
                  {item?.title}
                </Link>
              </td>

              {/* Owner(s) */}
              <td className="px-4 py-2">
                <div className="flex items-center gap-2">
                  <Avatar.Group
                    maxCount={2}
                    size={"small"}
                    maxPopoverTrigger="click"
                    maxStyle={{
                      color: "#f56a00",
                      backgroundColor: "#fde3cf",
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    {item?.owners?.map((owner, index) =>
                      owner?.type === "user" ? (
                        <div
                          key={index}
                          className="w-5 h-5 text-sm border border-black rounded-full flex items-center justify-center text-white"
                          style={{
                            backgroundColor: owner?.profile_image
                              ? "initial"
                              : getAvatarColor(owner?.name),
                          }}
                        >
                          {owner?.profile_image ? (
                            <img
                              src={owner?.profile_image}
                              alt="Profile"
                              className="w-full h-full object-cover rounded-full"
                            />
                          ) : (
                            getInitials(owner.name)
                          )}
                        </div>
                      ) : (
                        <span
                          key={index}
                          className="p-1 bg-gray-600 rounded-full"
                        >
                          <BiGlobe className="text-gray-300 text-xs" />
                        </span>
                      )
                    )}
                  </Avatar.Group>
                </div>
              </td>

              {/* Progress */}
              <td className="px-4 py-2">
                <Progress
                  type="circle"
                  size={50}
                  percent={item?.overall_gain}
                  strokeWidth={10}
                  strokeColor={
                    item?.overall_gain >= 0 && item?.overall_gain <= 29
                      ? "#FF2934"
                      : item?.overall_gain >= 30 && item?.overall_gain <= 49
                      ? "#FF8400"
                      : item?.overall_gain >= 50 && item?.overall_gain <= 69
                      ? "#FFF000"
                      : "#53DC0D"
                  }
                  trailColor="#f0f0f0"
                  format={(percent) => (
                    <span style={{ fontWeight: "medium", color: "black" }}>
                      {percent}%
                    </span>
                  )}
                />
              </td>

              {/* Description */}
              <td className="px-4 py-2">
                <div
                  className="p-2 border rounded-md"
                  style={{
                    border: "0.96px solid rgba(168, 175, 178, 0.72)",
                  }}
                >
                  {item?.description}
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default OkrReportsTable;
