import { Modal, Spin } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAvatarColor, getInitials } from "../../utils/helpers";
import { baseURL } from "../../utils/config";
import { MdOutlineModeEditOutline } from "react-icons/md";
import { api } from "./api";
import { countries } from "countries-list";

const ProfileModal = ({
  setProfileModal,
  profileModal,
  formDataNew,
  setformDataNew,
  handleSubmit,
}) => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const currentUser = user?.currentUser;
  const currentEmployee = currentUser?.employees[user?.currentCompany];
  const [error, setError] = useState(null);
  const [countriesList, setCountriesList] = useState([]);

  const [imageLoading, setImageLoading] = useState(false);

  const iconStyle = {
    color: "black",
    fontSize: "25px",
  };

  useEffect(() => {
    const fetchCountries = () => {
      try {
        const countryOptions = Object.values(countries).map((country) => {
          const name = country?.name; // Get the official name
          const formattedName = name
            ?.split(" ") // Split into words
            .map(
              (word) =>
                word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
            ) // Capitalize each word
            .join(" "); // Join words with spaces

          return {
            code: formattedName,
            name: formattedName,
          };
        });

        setCountriesList(countryOptions);
      } catch (error) {
        console.error("Error processing country data:", error);
      }
    };

    fetchCountries();
  }, []);

  const combinedUsername =
    `${formDataNew.first_name} ${formDataNew.last_name}`.trim();
  const [loading, setLoading] = useState(false);

  function changeHandeler(event) {
    const { name, value } = event.target;
    setformDataNew((prevFormData) => {
      return {
        ...prevFormData,
        [name]: value,
      };
    });
  }

  const handleImageUpload = async (e) => {
    const file = e.target.files[0];
    if (file) {
      const maxFileSize = 1 * 1024 * 1024;
      if (file.size > maxFileSize) {
        return;
      }

      const formData = new FormData();
      formData.append("profile_image", file);
      try {
        setImageLoading(true);
        setformDataNew((prevData) => ({
          ...prevData,
          image: "",
        }));
        const result = await api.postpayload(
          baseURL + "/profile-photo/",
          formData
        );
        setImageLoading(false);
        setformDataNew((prevData) => ({
          ...prevData,
          image: result.data.image_url,
        }));
      } catch (error) {
        setImageLoading(false);
        // Handle errors
      }
    }
  };

  const handleCancel = () => {
    setProfileModal(false);
  };

  useEffect(() => {
    if (currentEmployee && currentUser) {
      const [first_name = "", last_name = ""] =
        currentUser?.username.split(" ");
      setformDataNew({
        image: currentUser?.profile_image,
        first_name,
        last_name,
        email: currentUser?.email,
        companyName: currentEmployee?.company_name,
        country: currentEmployee?.country,
      });
    }
  }, [currentEmployee, currentUser, profileModal]);

  return (
    <Modal
      open={profileModal}
      onCancel={() => setProfileModal(false)}
      onOk={handleSubmit}
      okText="Update"
      width={550}
      // confirmLoading={loading}
    >
      <div className="p-4 pr-6 w-full">
        <h1 className="text-2xl font-semibold mb-2">Your Profile</h1>
        <p className="text-xs mb-1">Edit Your Profile</p>
        <p className="text-xs mb-4 text-red-500">
          Image size should be less than 1mb
        </p>
        <form className="grid grid-cols-4 gap-4 items-start">
          <div className="col-span-4">
            <div className="col-span-2 flex items-center justify-center flex-col">
              <div
                className="w-24 h-24 border text-3xl border-black rounded-full flex items-center justify-center text-white  relative"
                style={{
                  backgroundColor: formDataNew?.image
                    ? "initial"
                    : getAvatarColor(combinedUsername),
                }}
                size={30}
              >
                {formDataNew?.image ? (
                  <img
                    src={formDataNew?.image}
                    className="w-full h-full object-cover rounded-full"
                    alt="Profile"
                  />
                ) : (
                  getInitials(combinedUsername)
                )}
                <div className="absolute bottom-0 right-0 z-1">
                  <div className="bg-white rounded-full w-9 border h-9 border-black flex justify-center items-center">
                    <label htmlFor="image-upload">
                      {imageLoading ? (
                        <Spin style={iconStyle} />
                      ) : (
                        <MdOutlineModeEditOutline style={iconStyle} />
                      )}
                    </label>
                    <input
                      type="file"
                      id="image-upload"
                      accept="image/*"
                      style={{ display: "none" }}
                      onChange={handleImageUpload}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="mb-4">
              <label htmlFor="first-name-input" className="text-sm block">
                First Name
              </label>
              <input
                type="text"
                name="first_name"
                id="first_name"
                onChange={changeHandeler}
                value={formDataNew.first_name}
                className="w-full"
                required
              />
            </div>
            <div className="mb-4">
              <label htmlFor="last-name-input" className="text-sm block">
                Last Name
              </label>
              <input
                type="text"
                name="last_name"
                id="last_name"
                onChange={changeHandeler}
                value={formDataNew.last_name}
                className="w-full"
              />
            </div>
            <div className="mb-4">
              <label htmlFor="email-input" className="text-sm block">
                Email
              </label>
              <input
                type="email"
                id="email"
                name="email"
                value={formDataNew?.email}
                className="w-full"
                disabled
              />
            </div>
            <div className="mb-4">
              <label htmlFor="country-input" className="text-sm block">
                Country Name
              </label>
              <select
                name="country"
                id="country"
                onChange={changeHandeler}
                value={formDataNew?.country}
                placeholder="Select your Country"
                required
                className="w-full border p-2 rounded border-blue-500 hover:border-blue-500 focus:border-blue-500"
              >
                <option value="United States of America">
                  United States of America
                </option>
                {countriesList
                  ?.filter(
                    (country) => country?.name !== "United States of America"
                  )
                  ?.map((country) => (
                    <option key={country?.code} value={country?.code}>
                      {country?.name}
                    </option>
                  ))}
                {countriesList?.map((country) => (
                  <option key={country.code} value={country?.code}>
                    {country?.name}
                  </option>
                ))}
              </select>
            </div>
            <div>
              <label htmlFor="name-input" className="text-sm block">
                Company Name
              </label>
              <input
                type="text"
                name="companyName"
                id="companyName"
                onChange={changeHandeler}
                value={formDataNew?.companyName}
                className="w-full"
                disabled={currentEmployee?.rolename !== "Admin"}
              />
            </div>
          </div>
        </form>
      </div>
    </Modal>
  );
};

export default ProfileModal;
