import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useAppContext } from "../../components/StepByStepGuide/context";
import { useMount } from "react-use";
import { useNavigate } from "react-router-dom";
import CustomScrollbar from "../../components/global/CustomScrollBar";
import { updateTitleData } from "../../stores/slices/userAuthSlice";
import ScheduleMeetingModal from "./ScheduleMeetingModal";
import { handleOpenScheduleModal } from "../../stores/slices/okrSlice";
import { baseURL } from "../../utils/config";
import { FaRegEdit } from "react-icons/fa";
import { Tooltip } from "antd";
import ConfirmationModal from "./ConfirmationModal";
import { useDisclosure } from "@chakra-ui/react";
import { api } from "../../components/global/api";
import moment from "moment/moment";
import dayjs from "dayjs";

const TouchbaseSchedule = () => {
  const navigate = useNavigate();

  const okrSlice = useSelector((state) => state?.okr);
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const currentPlan = user?.currentPlan;
  const [formData, setformData] = useState();
  const currentUser = user?.currentUser;
  const currentEmployee = currentUser?.employees[user?.currentCompany];
  const [schedules, setSchedules] = useState([]);
  const [selectedSchedule, setSelectedSchedule] = useState({});
  const { isOpen, onOpen, onClose } = useDisclosure();

  const recurrenceOptions = [
    { title: "One time", value: "one_time", key: "one_time" },
    { title: "Every day", value: "daily", key: "daily" },
    {
      title: "Every week on same day",
      value: "weekly",
      key: "weekly",
    },
    {
      title: "Every month on same day",
      value: "monthly",
      key: "monthly",
    },
    {
      title: "Every year on same date",
      value: "yearly",
      key: "yearly",
    },
    { title: "Custom..", value: "custom", key: "custom" },
  ];

  const handleOpenModal = (item) => {
    setSelectedSchedule(item);
    onOpen();
  };

  const handleConfirmDelete = () => {
    if (selectedSchedule) {
      handleDeleteSchedule(selectedSchedule);
      setSelectedSchedule(null);
      onClose();
    }
  };

  if (currentPlan?.plan_name === "Free" && !currentPlan?.free_trial_status) {
    navigate("/settings?tab=Billing");
  }

  const fetchData = async () => {
    try {
      const response = await api.get(baseURL + "/userschedule-get/", {
        company_id: currentEmployee?.company_id,
      });
      setSchedules(response?.data);
    } catch (error) {
      console.log(error);
    }
  };

  const handleDeleteSchedule = async (schedule) => {
    try {
      const res = await api.delete(
        baseURL + `/schedule/?schedule_id=${schedule?.id}`
      );
      fetchData();
    } catch (error) {
      console.log(error);
    }
  };

  const handleScheduleEdit = (item) => {
    setformData((prevData) => ({
      ...prevData,
      id: item?.id,
      end_time: null,
      custom_frequency: item?.custom_frequency,
      custom_unit: null,
      end_condition: null,
      end_date: null,
      occurrences: null,
      templateSelected: `${item?.template} ${item?.template_title}`,
      name: item?.name,
      parent_val: `${item?.goal_id} goal`,
      parent_id: item?.goal_id,
      Cadence_schedule: item?.start_time,
      participations: item?.participations?.map((item) => item.id),
      recurrence: item?.recurrence,
      updateData: "updateData",
    }));
    dispatch(handleOpenScheduleModal(true));
  };

  const handleClose = async () => {
    setformData((prevData) => ({
      ...prevData,
      template: null,
      name: "",
      Cadence_schedule: null,
      participations: [],
      recurrence: "one_time",
    }));
    try {
      await fetchData();
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      dispatch(handleOpenScheduleModal(false));
    }
  };

  useEffect(() => {
    fetchData();
    dispatch(
      updateTitleData({
        title: "TouchBase",
        ClickLink: "TouchBase / Schedule",
      })
    );
  }, []);

  const getStrokeColor = (template_type) => {
    if (template_type === "quarterly_template") {
      return "#92CAC1";
    } else if (template_type === "monthly_template") {
      return "#F4DB77";
    } else {
      return "#EEB0B0";
    }
  };

  console.log("ss", schedules[1]?.upcoming_meeting);

  return (
    <div className="relative h-screen p-4">
      <p className="font-lato text-[#121213] font-semibold text-xl mb-2">
        All Schedules
      </p>
      <div
        className="flex flex-col p-4"
        style={{
          backgroundColor: "#F7F8FA",
          borderRadius: "16px",
          width: "100%",
          height: "75vh",
        }}
      >
        <div className="p-4 h-full bg-white rounded-lg">
          <CustomScrollbar>
            <div className="flex flex-col w-full">
              <div className="w-full">
                <table className="w-full table-auto border-collapse">
                  {/* Table Head */}
                  <thead className="bg-[#F7F8FA] text-[#5B5B5B] sticky top-0">
                    <tr>
                      <th className="text-left px-4 py-2 border">Name</th>
                      <th className="text-left px-4 py-2 border">Cadence</th>
                      <th className="text-left px-4 py-2 border">
                        Upcoming Meeting
                      </th>
                      <th className="text-left px-4 py-2 border">Template</th>
                      <th className="text-left px-4 py-2 border">
                        Participants
                      </th>
                      <th className="text-center px-4 py-2 border">Actions</th>
                    </tr>
                  </thead>
                  {/* Table Body */}
                  <tbody>
                    {schedules.length > 0 ? (
                      schedules.map((item, index) => (
                        <tr
                          key={index}
                          className="text-[#5B5B5B] font-medium text-sm hover:bg-gray-100"
                        >
                          <td className="p-3 border">{item?.name}</td>
                          <td className="p-3 border">
                            {recurrenceOptions?.find(
                              (option) => option.value === item?.recurrence
                            )?.title || "No recurrence set"}
                          </td>
                          <td className="p-2 border">
                            {item?.upcoming_meeting
                              ? new Date(item.upcoming_meeting).toISOString()
                                ? new Intl.DateTimeFormat("en-US", {
                                    year: "numeric",
                                    month: "long",
                                    day: "2-digit",
                                    hour: "2-digit",
                                    minute: "2-digit",
                                    hour12: true,
                                    timeZone: "UTC",
                                  }).format(new Date(item.upcoming_meeting))
                                : "Invalid Date"
                              : "No Upcoming Meeting"}
                          </td>
                          <td className="p-3 border flex items-center gap-x-2">
                            <svg
                              width="20"
                              height="20"
                              viewBox="0 0 20 20"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M9.55 1H1V11.1538H9.55V1ZM19 14.8462H1V19H19V14.8462ZM19 1H13.15V4.69231H19V1ZM19 7.46154H13.15V11.1538H19V7.46154Z"
                                stroke={getStrokeColor(item?.template_type)}
                                strokeWidth="1.5"
                                strokeLinejoin="round"
                              />
                            </svg>
                            {item?.template_title}
                          </td>
                          <td className="p-3 border text-center">
                            {item?.participations?.length}
                          </td>
                          <td className="p-3 border text-center ">
                            <div className="items-center flex justify-center gap-x-2">
                            <Tooltip>
                              <button
                                onClick={() => {
                                  handleOpenModal(item);
                                }}
                                className="bg-white transition-all rounded-lg flex items-center justify-center"
                              >
                                <svg
                                  width="16"
                                  height="18"
                                  viewBox="0 0 16 18"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M3 18C2.45 18 1.97917 17.8042 1.5875 17.4125C1.19583 17.0208 1 16.55 1 16V3H0V1H5V0H11V1H16V3H15V16C15 16.55 14.8042 17.0208 14.4125 17.4125C14.0208 17.8042 13.55 18 13 18H3ZM13 3H3V16H13V3ZM5 14H7V5H5V14ZM9 14H11V5H9V14Z"
                                    fill="currentColor"
                                  />
                                </svg>
                              </button>
                            </Tooltip>
                            <Tooltip>
                              <button
                                onClick={() => {
                                  handleScheduleEdit(item);
                                }}
                                className="bg-white transition-all rounded-lg text-lg flex items-center justify-center cursor-pointer"
                              >
                                <FaRegEdit />
                              </button>
                            </Tooltip>
                            </div>
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td
                          colSpan="6"
                          className="text-center px-4 py-2 text-gray-500"
                        >
                          No Schedule Created
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </CustomScrollbar>
        </div>

        <ScheduleMeetingModal
          isOpen={okrSlice?.openCreateScheduleModal}
          onClose={handleClose}
          formData={formData}
          setformData={setformData}
        />

        <ConfirmationModal
          isOpen={isOpen}
          onClose={onClose}
          onConfirm={handleConfirmDelete}
        />
      </div>
    </div>
  );
};

export default TouchbaseSchedule;
