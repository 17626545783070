// export const baseURL='https://api.imongu.com/imongu' 
export const baseURL = process.env.REACT_APP_BASE_URL;
export const AppURL = process.env.REACT_APP_URL;
export const googleClientId = process.env.REACT_APP_GOOGLE_CLIENT_ID;
export const NotifyURL = process.env.REACT_APP_NOTIFY_URL;
export const siteKey = process.env.REACT_APP_SITE_KEY;
export const REACT_REFRESH=false
export const basic_monthly = process.env.REACT_APP_BASIC_MONTHLY;
export const basic_yearly = process.env.REACT_APP_BASIC_YEARLY;
export const expanded_monthly = process.env.REACT_APP_EXPANDED_MONTHLY;
export const expanded_yearly = process.env.REACT_APP_EXPANDED_YEARLY;