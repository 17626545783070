import React from "react";
import { useState } from "react";
import { IoSend } from "react-icons/io5";
import { baseURL } from "../../../utils/config";
import { useSelector } from "react-redux";
import { Drawer } from "antd";
import { useEffect } from "react";
import CommentCard from "./CommentCard";
import { useRef } from "react";
import MentionInput from "./CustomMentionInput";
import { api } from "../../global/api";

const UpdateKeyResultComments = ({ update_key_id, onClose, title }) => {
  const [editState, setEditState] = useState("");

  const [loading, setLoading] = useState(false);
  const user = useSelector((state) => state.user);
  const currentUser = user?.currentUser;
  const [allComments, setAllComments] = useState([]);
  const [editedComment, setEditedComment] = useState("");
  const [mention, setMentions] = useState([]);
  const commentRef = useRef(null);
  const getAllComments = async () => {
    try {
      const res = await api.get(baseURL + "/comments/", {
        update_key_id: update_key_id,
      });
      setAllComments(res.data);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    if (update_key_id) getAllComments();
  }, [update_key_id]);
  const handleEditComment = async (commentData, currentEditorState) => {
    if (!currentEditorState) return;
    try {
      const res = await api.put(baseURL + "/comments/", {
        company_id: currentUser?.employees[user?.currentCompany]?.company_id,
        comment_id: commentData.comment_id,
        text: currentEditorState,
        update_key_id: update_key_id,
      });
    } catch (error) {
      console.log(error);
    } finally {
      getAllComments();
      setEditedComment("");
    }
  };

  const handleDeleteComment = async (comment_id) => {
    try {
      const res = await api.delete(baseURL + "/comments/", {
          comment_id: comment_id,
      });
      getAllComments();
    } catch (error) {
      console.log(error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const res = await api.post(baseURL + "/comments/", {
        company_id: currentUser?.employees[user?.currentCompany]?.company_id,
        text: editState,
        update_key_id: update_key_id,
      });
      setEditState("");
      getAllComments();
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const currentEmployee = currentUser?.employees;
        let company_id;
        if (currentEmployee && currentEmployee?.length) {
          company_id = currentEmployee[user?.currentCompany]?.company_id;
        }
        const response = await api.get(baseURL + "/assignowners/", {
          company_id: company_id,
        });
        setMentions(response.data);
      } catch (error) {
        console.log(error);
      }
    };

    fetchData();
  }, []);
  return (
    <Drawer
      open={update_key_id}
      onClose={onClose}
      zIndex={10}
      width={900}
      title={<HeaderComponennt title={title} />}
    >
      <div className="flex flex-col gap-y-4">
        <div className="p-2 grid gap-4">
          {allComments?.map((comment) => (
            <CommentCard
              key={comment?.comment_id}
              commentData={comment}
              handleDeleteComment={handleDeleteComment}
              isCommentEditing={comment?.comment_id === editedComment}
              setEditedComment={setEditedComment}
              handleEditComment={handleEditComment}
            />
          ))}
        </div>
        <form
          className="flex w-11/12 items-center gap-2 "
          onSubmit={handleSubmit}
        >
          <MentionInput 
            editState={editState}
            setEditState={setEditState}
            mention={mention}
            setMentions={setMentions}
          />
          <button
            type="submit"
            className="p-2 bg-primaryBg/80 text-white rounded-full hover:bg-primaryBg  transition-all"
            disabled={loading || !editState}
          >
            <IoSend />
          </button>
        </form>
      </div>
    </Drawer>
  );
};

export default UpdateKeyResultComments;
const HeaderComponennt = ({ title }) => {
  return <div>Discussion on {title}</div>;
};
