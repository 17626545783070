import { MoreOutlined } from "@ant-design/icons";
import { Avatar, Dropdown } from "antd";
import React, { useEffect, useState } from "react";
import { getInitials, timeAgo } from "../../../utils/helpers";
import { useSelector } from "react-redux";
import { baseURL } from "../../../utils/config";
import { MdDelete } from "react-icons/md";
import { BiEdit } from "react-icons/bi";
import MentionInput from "./CustomMentionInput";
import { api } from "../../global/api";

const CommentCard = ({
  commentData,
  handleDeleteComment,
  setEditedComment,
  isCommentEditing,
  handleEditComment,
}) => {
  const [editState, setEditState] = useState("");
  const user = useSelector((state) => state.user);
  const [mention, setMentions] = useState([]);
  const currentUser = useSelector((state) => state.user?.currentUser);

  useEffect(() => {
    setEditState(commentData?.text);
  }, [commentData?.text]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const currentEmployee = currentUser?.employees;
        let company_id;
        if (currentEmployee && currentEmployee?.length) {
          company_id = currentEmployee[user?.currentCompany]?.company_id;
        }
        const response = await api.get(baseURL + "/assignowners/", {
          company_id: company_id,
        });
        setMentions(response.data);
      } catch (error) {
        console.log(error);
      }
    };

    fetchData();
  }, []);

  const items = [
    {
      key: 1,
      label: "Delete",
      danger: true,
      icon: <MdDelete />,
      onClick: () => {
        handleDeleteComment(commentData.comment_id);
      },
    },
    {
      key: 2,
      label: "Edit",
      icon: <BiEdit />,
      onClick: () => {
        setEditedComment(commentData?.comment_id);
      },
    },
  ];
  return (
    <div className="flex gap-x-2 items-start p-2">
      <div className="">
        <Avatar size={24}>{getInitials(commentData?.username)}</Avatar>
      </div>
      <div className="flex flex-col w-full">
        <div className="flex justify-between items-center">
          <div className="flex items-center gap-2">
            <h4 className="font-semibold">{commentData?.username}</h4>
            <span className="text-xs">
              {timeAgo(commentData?.date_created)}
            </span>
          </div>
          {commentData?.user_id ===
            currentUser?.employees[user?.currentCompany]?.user_id && (
            <Dropdown menu={{ items }}>
              <MoreOutlined />
            </Dropdown>
          )}
        </div>

        {isCommentEditing ? (
          <div className="mt-2 flex flex-col">
            <MentionInput
              editState={editState}
              setEditState={setEditState}
              mention={mention}
              setMentions={setMentions}
              // onChange={(e) => {
              //   setEditedComment(String(e.target.value));
              //   setEditState(String(e.target.value));
              // }}
            />
            <div className="self-end flex gap-x-4 mt-4">
              <button
                // onClick={showModal}
                onClick={() => setEditedComment(null)}
                className="bg-white border border-red-600 text-red-600 rounded-full px-5 py-1.5 w-32"
              >
                Cancel
              </button>
              <button
                onClick={() => {
                  handleEditComment(commentData, editState);
                }}
                className="bg-primaryBg/90 hover:bg-primaryBg text-white rounded-full px-5 py-1.5 w-32"
              >
                Update
              </button>
            </div>
          </div>
        ) : (
          <MentionInput
            editState={editState}
            setEditState={setEditState}
            mention={mention}
            setMentions={setMentions}
            readOnly="readOnly"
          />
        )}
      </div>
    </div>
  );
};

export default CommentCard;
