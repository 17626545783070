import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { getInitials } from "../../../utils/helpers";
import { handleOpenCreateGoalModal } from "../../../stores/slices/okrSlice";
import { FaCaretLeft, FaCaretRight } from "react-icons/fa";
import {
  Button,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Portal,
} from "@chakra-ui/react";
import { ChevronDownIcon } from "@chakra-ui/icons";
import FilterComponent from "../../global/FilterComponent";
import CustomScrollbar from "../../global/CustomScrollBar";
import { MdChatBubbleOutline } from "react-icons/md";
import ChatBotComponent from "../../global/ChatBotComponent";
import {
  handleOpenPlanModal,
  updateTitleData,
} from "../../../stores/slices/userAuthSlice";
import EmployeeHeader from "../../global/EmployeeHeader";
import { PiTreeStructure } from "react-icons/pi";
import { List } from "@mui/icons-material";
import { IoMdList } from "react-icons/io";
import GoalsTable from "./GoalsTable";
import App from "../AlignmentTreeView";

const TableView = ({
  goals,
  goalsLoading,
  getOkrData,
  formData,
  setformData,
  owners,
  filterSession,
  setFilteredSession,
  hasGoalsParam,
}) => {
  const user = useSelector((state) => state?.user);
  const Sessions = useSelector((state) => state.user?.sessionList);
  const roleAccess = user?.roleAccess;
  const getInitialView = () => {
    if (roleAccess?.["Cascade-View"]?.Allow) {
      return "cascade";
    } else if (roleAccess?.["Tree-View"]?.Allow) {
      return "tree";
    } else {
      return "none";
    }
  };
  const [activeView, setActiveView] = useState(getInitialView);
  const [filterModal, setFilterModal] = useState(false);
  const [message, setMessage] = useState(false);
  const currentUser = useSelector((state) => state?.user?.currentUser);
  const dispatch = useDispatch();
  const [popoverOpen, setPopoverOpen] = useState(false);
  const [isChatbotOpen, setChatbotOpen] = useState(false);
  const [selectedgoal, setSelectedGoal] = useState(null);
  const [summarize, setSummarize] = useState(false);
  const navigate = useNavigate();
  const plan = useSelector((state) => state?.user?.currentPlan);

  const currentEmployee = currentUser?.employees[user?.currentCompany];
  const [visibleFields, setVisibleFields] = useState(["Goal Name"]);
  const handleToggle = (view) => {
    setActiveView(view);
  };

  const handlePrevious = () => {
    const currentIndex = Sessions.findIndex(
      (session) => session.value === filterSession
    );
    const previousIndex =
      (currentIndex - 1 + Sessions.length) % Sessions.length;
    setFilteredSession(Sessions[previousIndex].value);
  };

  useEffect(() => {
    dispatch(
      updateTitleData({
        title: "Goals",
        ClickLink: "Company / Goals",
      })
    );
  }, []);

  const handleNext = () => {
    setFilteredSession((prevValue) => {
      const currentIndex = Sessions.findIndex(
        (session) => session.value === prevValue
      );
      const nextIndex = (currentIndex + 1) % Sessions.length;
      return Sessions[nextIndex].value;
    });
  };

  const currentSession = Sessions.find(
    (session) => session.value === filterSession
  );

  const values = [
    "Goal Name",
    "Goal Owners",
    "Last Updated",
    "Objective Name",
    "Objective Owners",
    "Progress",
  ];
  const addField = (field) => {
    setVisibleFields((prevVisibleFields) => {
      if (!prevVisibleFields?.includes(field)) {
        return [...prevVisibleFields, field];
      }
      return prevVisibleFields;
    });
  };

  const removeField = (field, name) => {
    setformData((prevFormData) => {
      let updatedFormData = { ...prevFormData };

      if (name === "goal_owner" || name === "okr_owner") {
        updatedFormData[name] = [];
      } else if (name === "progress") {
        updatedFormData[name] = {
          comparison: "equal_to",
          value: null,
        };
      } else if (name === "lastUpdated") {
        updatedFormData[name] = {
          comparison: "before",
          value1: null,
          value2: null,
        };
      } else {
        updatedFormData[name] = "";
      }

      return updatedFormData;
    });

    setVisibleFields((prevVisibleFields) => {
      return prevVisibleFields?.filter((f) => f !== field);
    });
  };

  const [selectedGoalIndex, setSelectedGoalIndex] = useState(-1);

  const handleCheckboxChange = (index, goal) => {
    if (selectedGoalIndex === index) {
      setSelectedGoalIndex(-1);
      setSelectedGoal(null);
      setChatbotOpen(false);
    } else if (selectedGoalIndex >= 0 && selectedGoalIndex !== index) {
      return;
    } else {
      setSelectedGoalIndex(index);
      setSelectedGoal(goal);
    }
  };

  const handleChatbotButton = () => {
    if (!plan?.free_trial_status && !plan?.features?.AI_Chatbot) {
      dispatch(handleOpenPlanModal());
    } else {
      setChatbotOpen(!isChatbotOpen);
    }
  };

  useEffect(() => {
    setSelectedGoalIndex(-1);
    setChatbotOpen(false);
  }, [goals]);

  return (
    <div className="w-full h-screen p-4 font-poppins ">
      <EmployeeHeader
        companyName={currentEmployee?.company_name}
        initials={getInitials(currentEmployee?.company_name)}
      />
      <div
        className="  flex flex-col p-4"
        style={{
          backgroundColor: "#F7F8FA",
          borderRadius: "16px",
          width: "100%",
          height: "75vh",
        }}
      >
        <div className="p-4 h-full bg-white rounded-lg overflow-hidden">
          <span
            className="flex flex-col "
            style={{
              height: "8vh",
            }}
          >
            {activeView === "none" && (
              <h1 className="flex items-center gap-x-2 text-sm font-semibold font-lato">
                View by <IoMdList />
              </h1>
            )}
            <div className="flex items-center space-x-2 py-2 bg-white w-full rounded-lg">
              {roleAccess?.["Cascade-View"]?.Allow && (
                <button
                  onClick={() => handleToggle("cascade")}
                  className={`py-1 px-4 rounded flex gap-x-2 items-center ${
                    activeView === "cascade"
                      ? "bg-orange-100 text-[#F27E0D] border border-orange-100"
                      : "bg-white text-gray-700 border border-gray-300"
                  }`}
                >
                  <List />
                  Cascade/List
                </button>
              )}
              {roleAccess?.["Tree-View"]?.Allow && (
                <button
                  onClick={() => handleToggle("tree")}
                  className={`py-1 px-4 rounded flex gap-x-2 items-center ${
                    activeView === "tree"
                      ? "bg-orange-100 text-[#F27E0D] border border-orange-100"
                      : "bg-white text-gray-700 border border-gray-300"
                  }`}
                >
                  <PiTreeStructure className="rotate-90" />
                  Tree
                </button>
              )}
              <div className="flex-grow text-right text-gray-700">
                {!(activeView === "none") && (
                  <div className="flex text-right items-center justify-end gap-x-2">
                    <button onClick={handlePrevious} className="text-gray-700">
                      <FaCaretLeft />
                    </button>
                    <div className=" text-right text-gray-700">
                      {currentSession?.label}
                    </div>
                    <button onClick={handleNext} className="text-gray-700">
                      <FaCaretRight />
                    </button>
                  </div>
                )}
              </div>
              {activeView === "cascade" && (
                <span className="flex justify-between gap-x-4">
                  {selectedGoalIndex >= 0 ? (
                    <Button
                      px={4}
                      py={2}
                      width={"100px"}
                      transition="all 0.2s"
                      borderRadius="md"
                      borderWidth="1px"
                      _focus={{ boxShadow: "outline" }}
                      backgroundColor="white"
                      boxShadow={popoverOpen ? "outline" : ""}
                      color="#3C93C2"
                      fontWeight={"normal"}
                      borderColor="#3C93C2"
                      _hover={{
                        bg: "#F7FAFC",
                        color: "#287199",
                        borderColor: "#287199",
                        boxShadow: "outline",
                      }}
                      onClick={() => {
                        handleChatbotButton();
                        setSummarize(true);
                      }}
                      className="relative"
                    >
                      Summarize
                    </Button>
                  ) : (
                    <span className="flex justify-center items-center relative">
                      <Popover
                        isLazy
                        placement="bottom-end"
                        // lazyBehavior="keepMounted"
                        onClose={() => setPopoverOpen(false)}
                      >
                        <PopoverTrigger>
                          <Button
                            px={4}
                            py={2}
                            width={"100px"}
                            transition="all 0.2s"
                            borderRadius="md"
                            borderWidth="1px"
                            _focus={{ boxShadow: "outline" }}
                            backgroundColor="white"
                            boxShadow={popoverOpen ? "outline" : ""}
                            color="#3C93C2"
                            fontWeight={"normal"}
                            borderColor="#3C93C2"
                            _hover={{
                              bg: "#F7FAFC",
                              color: "#287199",
                              borderColor: "#287199",
                              boxShadow: "outline",
                            }}
                            onClick={() => setPopoverOpen(true)}
                            className="relative"
                          >
                            <span className="flex justify-evenly items-center gap-x-2">
                              <svg
                                width="14"
                                height="14"
                                viewBox="0 0 14 14"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M7.00017 13.7C6.80017 13.7 6.6335 13.6334 6.50017 13.5C6.36683 13.3667 6.30017 13.2 6.30017 13V7.55005L1.02517 0.900049C0.925167 0.766715 0.912667 0.633382 0.987667 0.500049C1.06267 0.366715 1.17517 0.300049 1.32517 0.300049H12.6752C12.8252 0.300049 12.9377 0.366715 13.0127 0.500049C13.0877 0.633382 13.0752 0.766715 12.9752 0.900049L7.70017 7.55005V13C7.70017 13.2 7.6335 13.3667 7.50017 13.5C7.36683 13.6334 7.20017 13.7 7.00017 13.7Z"
                                  fill="#0A4464"
                                />
                              </svg>
                              <p
                                style={{
                                  color: "#3C93C2",
                                }}
                              >
                                Filters
                              </p>{" "}
                              <ChevronDownIcon />
                            </span>
                          </Button>
                        </PopoverTrigger>
                        <Portal>
                          <PopoverContent>
                            <div>
                              <FilterComponent
                                filterModal={filterModal}
                                setFilterModal={setFilterModal}
                                setformData={setformData}
                                formData={formData}
                                owners={owners}
                                values={values}
                                removeField={removeField}
                                addField={addField}
                                visibleFields={visibleFields}
                                setVisibleFields={setVisibleFields}
                              />
                            </div>
                          </PopoverContent>
                        </Portal>
                      </Popover>
                    </span>
                  )}
                </span>
              )}
            </div>
          </span>
          {!goals || goalsLoading ? (
            <div className="h-full flex justify-center items-center mx-auto">
              <img src="/assets/images/Group 3.svg" />
            </div>
          ) : goals?.length ? (
            <div
              className="w-full "
              style={{
                height: "60vh",
              }}
            >
              {activeView === "cascade" ? (
                <CustomScrollbar>
                  <div className="w-full h-full overflow-y-auto">
                    <GoalsTable
                      goals={goals}
                      selectedGoalIndex={selectedGoalIndex}
                      handleCheckboxChange={handleCheckboxChange}
                      navigate={navigate}
                    />
                  </div>
                </CustomScrollbar>
              ) : activeView === "tree" ? (
                <div>
                  <App data={goals} getOkrData={getOkrData} />
                </div>
              ) : (
                <div>
                  <p>You do not have access to this feature.</p>
                </div>
              )}
              {roleAccess?.Chatbot?.Allow && (
                <div className="z-auto flex flex-col absolute bottom-5  right-5 p-2">
                  {isChatbotOpen && (
                    <div
                      style={{
                        transition:
                          "width 1s cubic-bezier(0.42, 0, 0.58, 1), height 1s cubic-bezier(0.42, 0, 0.58, 1)",
                      }}
                    >
                      {" "}
                      <ChatBotComponent
                        data={selectedgoal}
                        summarize={summarize}
                        setSummarize={setSummarize}
                        handleClose={handleChatbotButton}
                      />
                    </div>
                  )}
                  {!isChatbotOpen && message && (
                    <div
                      className=" mb-2 mr-5 text-sm shadow-sm right-10 rounded-t-md rounded-bl-md p-3"
                      style={{ backgroundColor: "#FFD580" }}
                    >
                      Hi 👋! I'm Joyce, your chatbot assistant. How may I help
                      you?{" "}
                    </div>
                  )}
                  <div className="flex justify-end">
                    <Button
                      p={2}
                      borderRadius="full"
                      backgroundColor="#063F5D"
                      color="white"
                      _hover={{
                        padding: "4px",
                        bg: "#042a45",
                      }}
                      onMouseEnter={() => setMessage(true)}
                      onMouseLeave={() => setMessage(false)}
                      onClick={handleChatbotButton}
                      className="relative flex justify-end"
                    >
                      <span className="flex justify-end items-center gap-x-2">
                        {isChatbotOpen ? (
                          <KeyboardArrowDownIcon className=" text-white" />
                        ) : (
                          <MdChatBubbleOutline className="text-white" />
                        )}
                      </span>
                    </Button>
                  </div>
                </div>
              )}
            </div>
          ) : (
            <div className="box-border m-0 h-full">
              {roleAccess?.Goal?.Create && (
                <div className="h-full flex flex-col items-center content-center justify-center">
                  {hasGoalsParam ? (
                    <h1 className="text-2xl font-medium text-center text-slate-500">
                      No Shared Goals. It's time to share
                      <br />
                      your Goal with others!
                    </h1>
                  ) : (
                    <h1>
                      No Goals here. It's time to define <br />
                      your first Goal!
                    </h1>
                  )}
                  <button
                    className="px-5 py-1.5 my-4 text-white bg-button hover:shadow-lg rounded-full "
                    onClick={() => {
                      if (
                        plan?.plan_name === "Free" &&
                        !plan?.free_trial_status
                      ) {
                        dispatch(handleOpenPlanModal());
                      } else {
                        dispatch(handleOpenCreateGoalModal({ isOpen: true }));
                      }
                    }}
                  >
                    Create Goals
                  </button>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default TableView;
