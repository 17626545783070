import React, { useState, useEffect } from "react";
import { Select, DatePicker } from "antd";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import { useDispatch, useSelector } from "react-redux";
import { getAvatarColor, getInitials } from "../../utils/helpers";
import { Units } from "../../utils/Constants";
import { BiGlobe } from "react-icons/bi";
import { baseURL } from "../../utils/config";
import { api } from "../global/api";
import { Spinner } from "@chakra-ui/react";

dayjs.extend(customParseFormat);

const units = [
  "Number",
  "Percentage(%)",
  "US Dollar ($)",
  "British pound(£)",
  "Euro(€)",
  "Create a custom unit",
];
const keyResults = ["Should increase to", "Should decrease to"];

const KeyResultForm = ({
  formData,
  getOkrData,
  saveData,
  titleError,
  setTitleError,
  targetError,
  setUnitError,
  unitError,
  setKeyResultError,
  keyResultError,
  setTargetError,
  initialError,
  setInitialError,
  deadlineError,
  setDeadlineError,
  changeHandler,
  setformData,
  resetformData,
  onChangeTimePicker,
  diffError,
  setDiffError,
  ownerError,
  setOwnerError,
  loading,
}) => {
  const dispatch = useDispatch();
  const userold = useSelector((state) => state.user);
  const currentUser = userold?.currentUser;

  const [owners, setOwners] = useState([]);

 

  useEffect(() => {
    const fetchData = async () => {
      try {
        const currentEmployee = currentUser?.employees[userold?.currentCompany];
        const response = await api.get(baseURL + "/assignowners/", {
          company_id: currentEmployee?.company_id,
        });
        setOwners(response.data);
      } catch (error) {
        // setError(error);
        console.log(error);
      }
    };

    fetchData();
  }, []);

  function changeHandeler(event) {
    const { name, value } = event.target;
    setformData((prevFormData) => {
      return {
        ...prevFormData,
        [name]: value,
      };
    });

    setTitleError(false);
    setTargetError(false);
    setInitialError(false);
  }
  function calculateDifference() {
    if (
      formData?.key_result_type === "Should increase to" &&
      formData.target_number - formData.initial_number < 0
    ) {
      setDiffError(1);
    } else if (
      formData?.key_result_type === "Should decrease to" &&
      formData.target_number - formData.initial_number > 0
    ) {
      setDiffError(2);
    } else {
      setDiffError(0);
    }
  }
  useEffect(() => {
    if (
      formData?.key_result_type ||
      formData?.target_number ||
      formData?.initial_number
    )
      calculateDifference();
  }, [formData?.key_result_type,formData?.target_number,formData?.initial_number]);

  return (
    <div className="w-full">
      <h1 className="p-2 m-2 text-3xl font-bold">Create Key Result</h1>
      <p className="p-2 m-2">
        All required fields are marked with an asterisk (*).
      </p>

      <div className="overflow-y-scroll flex bg-green gap-20 h-[53vh] w-full">
        <div className="w-full">
          <div className="flex p-2 m-2  gap-7 ">
            <label className="w-1/4 pt-2 text-sm font-normal leading-4 text-gray-700">
              Title*
            </label>
            <br />
            <div className="w-4/5 ">
              <input
                className="w-full  text-sm border border-gray-300 rounded-lg"
                type="text"
                name="title"
                id="title"
                onChange={changeHandeler}
                value={formData.title}
                placeholder="Enter key result name"
                // required
              />
              {titleError && (
                <p className="mt-1 text-red-500">Title is required</p>
              )}
            </div>
          </div>

          <div className="flex p-1 m-2 gap-7">
            <label className="w-1/4 pt-2 text-sm font-normal leading-4 text-gray-700">
              Key Result type*
            </label>
            <br />

            <div className="w-4/5">
              <Select
                className="w-full"
                placeholder="Should increase to"
                name="key_result_type"
                id="key_result_type"
                onChange={(value) => {
                  setKeyResultError(false);
                  setformData({ ...formData, key_result_type: value });
                }}
                value={formData.key_result_type}
              >
                {keyResults?.map((keyResult, index) => {
                  return (
                    <Select.Option value={keyResult} key={index}>
                      {keyResult}
                    </Select.Option>
                  );
                })}
              </Select>
              {keyResultError && (
                <p className="mt-1 text-red-500">Key Result Type is required</p>
              )}
            </div>
          </div>

          <div className="flex p-1 m-2 gap-7">
            <label className="w-1/4 pt-2 text-sm font-normal leading-4 text-gray-700">
              Metric*
            </label>
            <br />

            <div className="w-4/5">
              <Select
                className="w-full"
                name="unit"
                id="unit"
                onChange={(value) => {
                  setUnitError(false);
                  setformData({ ...formData, unit: value });
                }}
                value={formData?.unit}
                placeholder="Not Set"
              >
                {Units?.map((unit, index) => {
                  return (
                    <Select.Option value={unit?.value} key={index}>
                      {unit.label}
                    </Select.Option>
                  );
                })}
              </Select>
              {unitError && (
                <p className="mt-1 text-red-500">Unit is required</p>
              )}
            </div>
          </div>

          <div className="flex p-2 m-2 gap-7">
            <label className="w-1/4 pt-2 text-sm font-normal leading-4 text-gray-700">
              Target Number*
            </label>
            <br />

            <div className="w-4/5 ">
              <input
                className="w-48 px-2 py-1 text-sm border border-gray-300 rounded-lg"
                name="target_number"
                id="target_number"
                onChange={(e) => {
                  changeHandeler(e);
                }}
                value={formData.target_number}
                placeholder="Enter Target Number"
                type="number"
                // required
              ></input>

              {targetError && (
                <p className="mt-1 text-red-500">Target number is required</p>
              )}
            </div>
          </div>

          <div className="flex p-2 m-2 gap-7">
            <label className="w-1/4 pt-2 text-sm font-normal leading-4 text-gray-700">
              Initial Number*
            </label>
            <br />

            <div className="w-4/5 ">
              <input
                className="w-full px-2 py-1 text-sm border border-gray-300 rounded-lg"
                name="initial_number"
                id="initial_number"
                onChange={(e) => {
                  changeHandeler(e);
                }}
                value={formData.initial_number}
                placeholder="Enter initial Number"
                type="number"
                // required
              ></input>
              {diffError === 1 ? (
                <p className="mt-1 text-red-500">
                  Initial Number should be less than target{" "}
                </p>
              ) : diffError === 2 ? (
                <p className="mt-1 text-red-500">
                  Initial Number should be greater than target{" "}
                </p>
              ) : null}

              {initialError && (
                <p className="mt-1 text-red-500">Initial number is required</p>
              )}
            </div>
          </div>

          <div className="flex p-2 m-2 gap-7">
            <label className="w-1/4 pt-2 text-sm font-normal leading-4 text-gray-700">
              Description
            </label>
            <br />

            <div>
              <textarea
                rows={4}
                placeholder=""
                name="description"
                id="description"
                className="px-2 py-1 text-sm border border-gray-300 rounded-lg w-48 h-15"
                onChange={changeHandeler}
                value={formData.description}
              />
            </div>
          </div>

          <div className="flex p-2 m-2 gap-7">
            <label className="w-1/4 pt-2 text-sm font-normal leading-4 text-gray-700">
              Owner*
            </label>
            <br />

            <div className="w-4/5 ">
              <Select
                className="w-full"
                placeholder="search for a Member"
                name="owner"
                id="owner"
                mode="multiple"
                onChange={(value) => {
                  setOwnerError(false);
                  setformData({ ...formData, owner: value });
                }}
                value={formData.owner}
              >
                {owners?.map((owner, index) => {
                  return (
                    <Select.Option
                      value={owner?.id + " " + owner?.type}
                      key={index}
                    >
                      <div className="flex items-center gap-x-2 ">
                        {owner?.type === "user" ? (
                          <div
                            className="w-5 h-5 text-sm border border-black rounded-full flex items-center justify-center text-white relative"
                            style={{
                              backgroundColor: owner?.profile_image
                                ? "initial"
                                : getAvatarColor(owner?.name),
                            }}
                            size={18}
                          >
                            {owner?.profile_image ? (
                              <img
                                src={owner?.profile_image}
                                alt="Profile"
                                style={{
                                  width: "100%",
                                  height: "100%",
                                  objectFit: "cover",
                                  borderRadius: "50%",
                                  display: "block",
                                }}
                              />
                            ) : (
                              getInitials(owner.name)
                            )}
                          </div>
                        ) : (
                          <span className="p-1 bg-gray-600 rounded-full">
                            <BiGlobe className="text-gray-300 text-xs" />
                          </span>
                        )}
                        <span>{owner.name}</span>
                      </div>
                    </Select.Option>
                  );
                })}
              </Select>
              {ownerError && (
                <p className="mt-1 text-red-500">Owner is required</p>
              )}
            </div>
          </div>
          <p className="ml-48 text-xs">
            Key Results can be assigned to multiple people and/or teams
          </p>

          <div className="flex p-2 m-2 gap-7">
            <label className="w-1/4 pt-2 text-sm font-normal leading-4 text-gray-700">
              Deadline*
            </label>

            <div className="w-4/5 pl-5">
              <DatePicker
                className=" border-gray-300 "
                size=" "
                onChange={onChangeTimePicker}
                allowClear={false}
                style={{
                  width: "100%",
                }}
                defaultValue={dayjs(formData?.deadline)}
              />
              {deadlineError && (
                <p className="mt-1 text-red-500">Deadline is required</p>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="flex justify-end mt-4 gap-x-2">
        <button
          onClick={saveData}
          disabled={loading}
          className="bg-blue-500 hover:bg-blue-600 text-white font-semibold py-2 px-4 rounded"
        >
          Save
          {loading && (
            <Spinner bg={"transparent"} size="xs" color="white.500" />
          )}
        </button>
      </div>
    </div>
  );
};

export default KeyResultForm;
