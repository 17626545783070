import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import EmployeeHeader from "../components/global/EmployeeHeader";
import CustomScrollbar from "../components/global/CustomScrollBar";
import CalendarComponent from "../components/CalendarComponent";
import TableData from "../components/TableData";
import { baseURL } from "../utils/config";
import TableKeyData from "../components/TableKeyData";
import { updateTitleData } from "../stores/slices/userAuthSlice";
import { api } from "../components/global/api";

const DashboardOwner = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [data, setData] = useState();
  const [keyData, setKeyData] = useState();

  const currentUser = useSelector((state) => state?.user?.currentUser);
  const user = useSelector((state) => state?.user);

  const getFirstName = (name) => {
    if (!name) return "";
    const firstName = name.split(" ")[0];
    return firstName.charAt(0).toUpperCase() + firstName.slice(1);
  };

  const name = getFirstName(currentUser?.username);

  const getKeyData = async () => {
    const currentCompany = user?.currentUser?.employees[user?.currentCompany];
    try {
      const response = await api.get(baseURL + "/shared-okr/", {
        company_id: currentCompany?.company_id,
      });
      setKeyData(response?.data);
    } catch (error) {
      console.error(error);
    }
  };

  const getOkrData = async () => {
    const currentCompany = user?.currentUser?.employees[user?.currentCompany];
    try {
      const response = await api.get(baseURL + "/goal/", {
        company_id: currentCompany?.company_id,
      });
      setData(response?.data);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getOkrData();
    getKeyData();
    dispatch(
      updateTitleData({
        title: "Dashboard",
        ClickLink: null,
      })
    );
  }, []);

  return (
    <div className="w-full h-screen p-4 font-poppins  ">
      <EmployeeHeader Name={name} />
      <div
        className="flex flex-col p-4 "
        style={{
          backgroundColor: "#F7F8FA",
          borderRadius: "16px",
          width: "100%",
          height: "75%",
        }}
      >
        <div className="overflow-y-auto w-full">
          <CustomScrollbar>
            <div className="grid grid-cols-1 md:grid-cols-3 gap-4 w-full">
              {/* Left Section (Goals/OKRs) */}
              <div className="md:col-span-2 space-y-3">
                <div className="p-6 bg-white rounded-lg shadow-md">
                  <div className="flex justify-between items-center mb-6">
                    <h2 className="text-xl font-bold">Goals/OKRs Overview</h2>
                    <Link
                      to="/company/goals"
                      className="text-blue-500 hover:underline"
                    >
                      View All
                    </Link>
                  </div>
                  <div>
                    <TableData data={data} />
                  </div>
                </div>
                <div className="p-6 bg-white rounded-lg shadow-md">
                  <div className="flex justify-between items-center mb-6">
                    <h2 className="text-xl font-bold">
                      Objective or Key Result shared to Team members
                    </h2>
                    <Link
                      to={"/company/goals?goals=shared_okr"}
                      className="text-blue-500 hover:underline"
                    >
                      View All
                    </Link>
                  </div>
                  <TableKeyData teams="teams" data={keyData} />
                </div>
              </div>

              {/* Right Section (Calendar) */}
              <div className="p-4 bg-white rounded-lg shadow-md">
                <CalendarComponent />
              </div>
            </div>
          </CustomScrollbar>
        </div>
      </div>
    </div>
  );
};

export default DashboardOwner;
